import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../components/ReactConfirmMountPoint.ts";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useDataExport, useQueryDataExportFormOptions } from "../../api/DataExport.ts";
import { v4 } from "uuid";

export interface ExportedRunsProps {}

interface Values {
  uuid: string;
  customer_group_uuid?: string;
}

export const ExportCustomersDialog = ({
  show,
  proceed
}: ConfirmDialogProps<ExportedRunsProps, false | string>) => {
  const { data: formOptions } = useQueryDataExportFormOptions();
  const { mutateAsync: dataExport } = useDataExport("customers");
  const handleClose = () => {
    proceed(false);
  };

  const validationSchema = Yup.object<Values>().shape({
    uuid: Yup.string().uuid().required(),
    customer_group_uuid: Yup.string()
      .uuid()
      .optional()
      .oneOf(formOptions?.customer_groups?.map(({ uuid }: any) => uuid) ?? [])
  });

  const formik = useFormik<Values>({
    initialValues: {
      uuid: v4()
    },
    validationSchema,
    onSubmit: async values => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });
      await dataExport(validated);

      proceed(validated.uuid);
    }
  });

  const { handleSubmit, dirty, values } = formik;
  return (
    <Dialog open={show} onClose={handleClose} fullWidth>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Export Orders with Credit</DialogTitle>
          <DialogContent>
            <Stack direction="column" justifyContent="space-between" gap={6} my={3}>
              <Field name="customer_group_uuid" value={values.customer_group_uuid}>
                {({ field, form, meta }: FieldProps) => {
                  return (
                    <FormControl>
                      <InputLabel id="customer-group-select-label" margin="dense" shrink>
                        Customer Group
                      </InputLabel>
                      <Select
                        labelId="customer-group-select-label"
                        id="customer-group-select"
                        label="Customer Group"
                        fullWidth
                        value={field.value || ""}
                        onChange={({ target }) => {
                          form.setFieldValue(field.name, target.value);
                        }}
                      >
                        {formOptions?.customer_groups?.map((record: any) => (
                          <MenuItem key={record.uuid} value={record.uuid}>
                            {record.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>
                    </FormControl>
                  );
                }}
              </Field>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" color="primary" disabled={!dirty}>
              Export
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export const exportCustomersDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<ExportedRunsProps, false | string>) => (
    <ExportCustomersDialog {...props} />
  ))
);

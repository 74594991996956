import { useParams } from "react-router-dom";
import {
  CardActions,
  CardContent,
  Link as MuiLink,
  Stack,
  Typography as MuiTypography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Call as CallIcon } from "@mui/icons-material";
import React from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import {
  statisticsDateRangeFilter,
  useQueryOneUser,
  useQuerySalesRepStatistics
} from "../../../api/User.ts";
import { useAtomValue } from "jotai";
import { ListOrders, ListOrdersDiscount } from "./SalesRepStatisticsCard.tsx";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const AboutIcon = styled.span`
  display: flex;
  svg {
    width: 14px;
    height: 14px;
  }
`;

function SalesRepCard({ sales_rep_user_uuid }: { sales_rep_user_uuid?: string }) {
  const { uuid } = useParams();
  const { data: sales_rep } = useQueryOneUser(sales_rep_user_uuid ?? uuid);
  const dateRange = useAtomValue(statisticsDateRangeFilter);
  const { data: statistics } = useQuerySalesRepStatistics(uuid ? [uuid] : [], dateRange);
  return (
    <>
      <CardContent>
        <Typography gutterBottom variant="h3" component="div">
          {sales_rep?.full_name}
        </Typography>

        {!!sales_rep?.phone_number && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <CallIcon />
            </AboutIcon>
            <MuiLink href={`tel:${sales_rep.phone_number}`}>{sales_rep.phone_number}</MuiLink>
          </Stack>
        )}
        {!!sales_rep?.email && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <AlternateEmailIcon />
            </AboutIcon>
            <MuiLink href={`mailto:${sales_rep.email}`}>{sales_rep.email}</MuiLink>
          </Stack>
        )}

        {statistics && (
          <List sx={{ width: "100%" }} dense>
            <ListItem disableGutters={true}>
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                  sx={{
                    minHeight: "0px",
                    padding: 0,
                    ".MuiAccordionSummary-content": {
                      margin: 0,
                      padding: 0,
                      justifyContent: "space-between"
                    }
                  }}
                >
                  <span>Invoiced:</span>
                  <span>{toDecimalFromFloat(statistics?.invoiced?.sum ?? 0)}</span>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListOrders
                    orders={statistics?.invoiced?.rows ?? []}
                    date_attribute={{ label: "Invoiced", attribute: "is_invoiced_at" }}
                  />
                </AccordionDetails>
              </Accordion>
            </ListItem>

            <ListItem disableGutters={true}>
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                  sx={{
                    minHeight: "0px",
                    padding: 0,
                    ".MuiAccordionSummary-content": {
                      margin: 0,
                      padding: 0,
                      justifyContent: "space-between"
                    }
                  }}
                >
                  <span>Average discount:</span>

                  <span>{statistics?.avg_discount.avg_percentage ?? 0}%</span>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <ListOrdersDiscount orders={statistics?.avg_discount?.rows ?? []} />
                </AccordionDetails>
              </Accordion>
            </ListItem>

            <ListItem disableGutters={true} secondaryAction={<Typography>{"N/A"}</Typography>}>
              <ListItemText primary={<Typography>Commission:</Typography>} />
            </ListItem>

            <ListItem disableGutters={true} secondaryAction={<Typography>{"N/A"}</Typography>}>
              <ListItemText primary={<Typography>RPL:</Typography>} />
            </ListItem>

            <ListItem disableGutters={true} secondaryAction={<Typography>{"N/A"}</Typography>}>
              <ListItemText primary={<Typography>EPL:</Typography>} />
            </ListItem>
          </List>
        )}
      </CardContent>
      <CardActions></CardActions>
    </>
  );
}

export default SalesRepCard;

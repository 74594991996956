import { Alert } from "@mui/material";
import { usePusherConnection } from "../../hooks/usePusherConnection.ts";

export const PusherConnectionState = () => {
  const { errorCode } = usePusherConnection();

  if (!errorCode) {
    return null;
  }

  if (errorCode === 4100) {
    return (
      <Alert severity="warning" sx={{ mb: 2 }}>
        Disconnected from realtime updates due to an issue on our end. Please try again later. Any
        edits from you will be saved but not reflected automatically unless the page is refreshed.
      </Alert>
    );
  }

  return (
    <Alert severity="warning" sx={{ mb: 2 }}>
      Disconnected from realtime updates due to an issue on our end. Please try again later.
    </Alert>
  );
};

import OrderList from "../pages/order/OrderList.tsx";
import useAuth from "../hooks/useAuth.ts";
import { useEffect, useMemo } from "react";
import SalesRepDashboard from "../pages/user/sales_rep/SalesRepDashboard.tsx";
import { useNavigate } from "react-router-dom";

export const DynamicDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.roles.includes("FITTER")) {
      navigate("/order-fitting", { replace: true });
    }
  }, [navigate, user]);

  return useMemo(() => {
    if (user && user.roles.includes("SALES_REP")) {
      return <SalesRepDashboard sales_rep_user_uuid={user.uuid} />;
    }

    return <OrderList />;
  }, [user]);
};

import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useMutationAbstract,
  useMutationDeleteModelV2,
  useQueryFormOptionsModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import { createErrorInfo } from "../utils/createErrorInfo.ts";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { isNil, map, omitBy, unionBy, uniq } from "lodash";
import { atom, useAtomValue } from "jotai";
import { addWeeks, endOfDay, format, startOfDay, subDays } from "date-fns";
import { SearchResult } from "minisearch";
import { atomWithLocation } from "jotai-location";
import { useMemo } from "react";

export const QUERY_KEY = "order-fitting";

const start = subDays(new Date(), 3);
export const dateRangeFilterAtom = atom({
  start: startOfDay(start),
  end: endOfDay(addWeeks(start, 2))
});

const locationAtom = atomWithLocation();

export const queryTypeFilterAtom = atom(
  get => get(locationAtom).searchParams?.get("query_type"),
  (get, set, value) => {
    const currentSearchParams = new URLSearchParams(get(locationAtom).searchParams);
    currentSearchParams.set("query_type", value as string);
    set(locationAtom, prev => ({
      ...prev,
      searchParams: currentSearchParams
    }));
  }
);

export const customerGroupFilterAtom = atom(
  get => get(locationAtom).searchParams?.get("customer_group"),
  (get, set, value) => {
    const currentSearchParams = new URLSearchParams(get(locationAtom).searchParams);
    if (value === undefined) {
      currentSearchParams.delete("customer_group");
    } else {
      currentSearchParams.set("customer_group", value as string);
    }

    set(locationAtom, prev => ({
      ...prev,
      searchParams: currentSearchParams
    }));
  }
);

export const globalFilterAtom = atom<string | undefined>(undefined);
export const fitterUsersFilterAtom = atom<string[] | null | undefined>(undefined);
export const taFittersFilterAtom = atom<string[] | undefined>(undefined);
export const jobTypesFilterAtom = atom<string[] | undefined>(undefined);
export const jobStatusesFilterAtom = atom<string[] | undefined>(undefined);
export const withTAsFilterAtom = atom<boolean | undefined>(undefined);
export const isPowderCoatFilterAtom = atom<boolean | undefined>(undefined);
export const departmentsFilterAtom = atom<string[] | undefined>(undefined);
export const goToSearchAtom = atom<string | undefined>(undefined);
export const goToSearchResultsAtom = atom<
  { column_name: string; row_index: any; score: number; result: SearchResult }[] | undefined
>(undefined);
export const goToSearchResultCursorAtom = atom<number>(0);

export const paramsSummaryAtom = atom(get => {
  const dateRangeFilter = get(dateRangeFilterAtom);
  const queryType = get(queryTypeFilterAtom) ?? "";
  return {
    job_booked_at_between: {
      start: format(dateRangeFilter.start, "yyyy-MM-dd"),
      end: format(dateRangeFilter.end, "yyyy-MM-dd")
    },
    customer_group_uuid: get(customerGroupFilterAtom),
    query_type: ["back_to_rep", "to_be_rebooked"].includes(queryType) ? "booked" : queryType,
    job_status: get(jobStatusesFilterAtom)
  };
});

export const paramsAtom = atom(get => {
  const globalFilter = get(globalFilterAtom);
  const queryType = get(queryTypeFilterAtom) ?? "";
  if (globalFilter) {
    return {
      customer_group_uuid: get(customerGroupFilterAtom),
      query_type: ["back_to_rep", "to_be_rebooked"].includes(queryType) ? "booked" : queryType,
      search_order_reference_number: globalFilter
    };
  }

  const dateRangeFilter = get(dateRangeFilterAtom);
  return omitBy(
    {
      job_booked_at_between: {
        start: format(dateRangeFilter.start, "yyyy-MM-dd"),
        end: format(dateRangeFilter.end, "yyyy-MM-dd")
      },
      customer_group_uuid: get(customerGroupFilterAtom),
      fitter_user_uuids: get(fitterUsersFilterAtom),
      ta_fitters_uuids: get(taFittersFilterAtom),
      job_types: get(jobTypesFilterAtom),
      query_type: ["back_to_rep", "to_be_rebooked"].includes(queryType) ? "booked" : queryType,
      job_status: get(jobStatusesFilterAtom),
      with_ta: get(withTAsFilterAtom),
      is_powdercoat: get(isPowderCoatFilterAtom),
      department_uuids: get(departmentsFilterAtom)
    },
    isNil
  );
});

/**
 * Queries and fetches booked orders based on the provided filters.
 *
 * @return {QueryResult} The result of the query containing booked orders data.
 */
export const useQueryOrderFittingBooked = () => {
  const paramsFilter = useAtomValue(paramsAtom);
  const queryTypeFilter = useAtomValue(queryTypeFilterAtom);
  const fitterUsersFilter = useAtomValue(fitterUsersFilterAtom);
  const queryResult = useQuery({
    enabled: !!queryTypeFilter && fitterUsersFilter !== undefined,
    queryKey: [QUERY_KEY, "list", paramsFilter],
    queryFn: ({ queryKey: [, , filters], signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/booked`, {
          signal,
          params: { filters }
        })
        .then(({ data }) => data),
    staleTime: 2000
  });

  const uniqueOrderUuids = useMemo(() => {
    return uniq(map((queryResult.data ?? []) as any[], "order.uuid"));
  }, [queryResult.data]);

  const queryClient = useQueryClient();

  /**
   * Handle updates to rows by comparing and updating them from the provided list of updated rows.
   *
   * @param {Array.<any>} updatedRows - The list of rows containing updated data.
   */
  const handleRowsUpdates = (updatedRows: any) => {
    const updatedIds = updatedRows?.map((row: any) => row.id);

    queryClient.setQueriesData(
      { queryKey: [QUERY_KEY, "list"], exact: false },
      (currentRows: any) => {
        return currentRows?.map((currentRow: any) => {
          if (updatedIds.includes(currentRow.id)) {
            return updatedRows?.find((row: any) => row.id === currentRow.id);
          }

          return currentRow;
        });
      }
    );
  };

  useEvent(useChannel("OrderUpdated"), "handled", (evt: any) => {
    if (!uniqueOrderUuids.includes(evt.event.order_uuid)) {
      return;
    }

    axios
      .get(`/api/${QUERY_KEY}/booked`, {
        params: {
          filters: {
            ...paramsFilter,
            order_uuid: evt.event.order_uuid
          }
        }
      })
      .then(({ data }) => data)
      .then(handleRowsUpdates);
  });

  useEvent(useChannel("OrderFittingCreated"), "handled", (evt: any) => {
    if (paramsFilter.query_type === "pending") {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, "list"], exact: false });
      return;
    }

    if (!uniqueOrderUuids.includes(evt.event.order_uuid)) {
      return;
    }

    axios
      .get(`/api/${QUERY_KEY}/booked`, {
        params: {
          filters: {
            ...paramsFilter,
            order_uuid: evt.event.order_uuid
          }
        }
      })
      .then(({ data: updatedRows }) => {
        queryClient.setQueriesData(
          { queryKey: [QUERY_KEY, "list"], exact: false },
          (currentRows: any) => {
            return unionBy(currentRows, updatedRows, "id");
          }
        );
      });
  });

  useEvent(useChannel("OrderFittingDeleted"), "handled", (evt: any) =>
    queryClient.setQueriesData({ queryKey: [QUERY_KEY, "list"], exact: false }, (old: any) =>
      old?.filter((row: any) => row.uuid !== evt.aggregate_id)
    )
  );

  useEvent(useChannel("OrderFitting"), "updated", (evt: any) => {
    if (!uniqueOrderUuids.includes(evt.order_uuid)) {
      return;
    }

    axios
      .get(`/api/${QUERY_KEY}/booked`, {
        params: {
          filters: {
            ...paramsFilter,
            order_uuid: evt.order_uuid
          }
        }
      })
      .then(({ data }) => data)
      .then(handleRowsUpdates);
  });

  return queryResult;
};

export const useQueryOrderFittingsSummary = () => {
  const paramsFilter = useAtomValue(paramsSummaryAtom);
  const queryTypeFilter = useAtomValue(queryTypeFilterAtom);
  const fitterUsersFilter = useAtomValue(fitterUsersFilterAtom);

  return useQuery({
    enabled: !!queryTypeFilter && fitterUsersFilter !== undefined,
    queryKey: [QUERY_KEY, "summary", paramsFilter],
    queryFn: ({ queryKey: [, , filters], signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/summary`, {
          signal,
          params: { filters }
        })
        .then(({ data }) => data),
    staleTime: 2000
  });
};

export const useQueryOrderFittingFormOptions = () =>
  useQueryFormOptionsModel<{
    job_types: string[];
    job_booked_time_slots: string[];
    install_confirmation_statuses: string[];
    rebook_reasons: string[];
    job_statuses: string[];
    fitter_users: any[];
    rectification_departments: any[];
    error_reasons: any[];
    product_lines: any[];
  }>(QUERY_KEY);

export const useMutationSaveOrderFitting = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutationAbstract<{
    uuid: string;
    order_uuid: string;
    fitter_user_uuid?: string | null;
    rectification_department_uuid?: string | null;
    error_reason_uuid?: string | null;
    error_detail?: string | null;
    job_booked_at?: Date | null;
    job_booked_at_end?: Date | null;
    job_type?: string | null;
    job_booked_time_slot?: string | null;
    install_time_allowed_in_hrs?: number | null;
    install_confirmation_status?: string | null;
    rebook_reason?: string | null;
    rebook_reason_notes?: string | null;
    job_status?: string | null;
    installation_notes?: string | null;
    is_on_hold_at?: Date | null;
    ta_count?: number | null;
    ta_time?: number | null;
    ta_fitters_uuids?: string[] | null;
    powdercoat_sent_at?: Date | null;
    powdercoat_eta_at?: Date | null;
    powdercoat_arrive_at?: Date | null;
    powdercoat_notes?: string | null;
    factory_completed_at?: Date | null;
    check_measure_submitted_at?: Date | null;
    cutting_sheet_processed_at?: Date | null;
    job_booked_sequence?: number | null;
    notes_reference?: string | null;
    to_factory_at?: Date | null;
    packs?: string | null;
    is_powdercoat?: boolean | null;
    container?: number | null;
    bay?: string | null;
    schedule_notes?: string | null;
  }>({
    mutationKey: [QUERY_KEY, "save"],
    mutationFn: input => axios.post(`/api/${QUERY_KEY}`, input),
    onError: error => {
      const { errorCode, errorMessage } = createErrorInfo(error as AxiosError);

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
        key: errorCode
      });
    }
  });
};

export const useMutationDeleteOrderFitting = () =>
  useMutationDeleteModelV2(QUERY_KEY, {
    pusherChannelName: "OrderFittingDeleted"
  });

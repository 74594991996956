export function createProxyWithErrorHandler<T extends object>(obj: T, path: string[] = []): T {
  if (obj === null || typeof obj !== "object") {
    throw new Error("createProxyWithErrorHandler can only be used with objects.");
  }

  return new Proxy(obj, {
    get(target, prop, receiver) {
      const currentPath = [...path, String(prop)];
      if (prop in target || typeof prop === "symbol" || prop === "toJSON" || prop === "length") {
        const value = Reflect.get(target, prop, receiver);
        if (value && typeof value === "object" && value !== null) {
          return createProxyWithErrorHandler(value, currentPath);
        }
        return value;
      } else {
        console.error(`Property ${currentPath.join(".")} does not exist on the target object.`);
        return undefined;
      }
    }
  });
}

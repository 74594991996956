import React from "react";
import { Chip, List, Typography } from "@mui/material";
import { QUERY_KEY } from "../../../api/Order";
import useIsLoading from "../../../hooks/useIsLoading";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IconText } from "../../../components/IconText";
import { useOrderMonetaryValues } from "../components/useOrderMonetaryValues";
import { LoadableListItem } from "../components/LoadableListItem";
import { useFeature } from "use-feature";

const PaymentTermsTable: React.VFC<{
  payment_term_config?: {
    config: {
      deposit: number;
      "pre-delivery": number;
      final: number;
    };
  };
  order?: any;
}> = ({ payment_term_config, order }) => {
  const isLoading = useIsLoading([QUERY_KEY]);

  const monetaryValues = useOrderMonetaryValues(order);
  const debug = useFeature("FEATURE_DEBUG_ORDER");

  const depositPercentage = payment_term_config?.config?.deposit ?? (order?.is_qbcc ? 20 : 40);
  const preDeliveryPercentage =
    payment_term_config?.config?.["pre-delivery"] ?? (order?.is_qbcc ? 30 : 40);
  const finalPercentage = payment_term_config?.config?.final ?? (order?.is_qbcc ? 50 : 20);

  return (
    <List dense>
      {!!order?.deposit_amount && (
        <LoadableListItem
          label={<Typography variant="body1">Deposit:</Typography>}
          value={
            <IconText
              startIcon={!!order?.deposit_amount_paid_at && <CheckCircleIcon color="primary" />}
            >
              <Typography>
                {`${monetaryValues.deposit_paid} / ${monetaryValues.deposit}`}{" "}
              </Typography>
              {debug && !!payment_term_config && (
                <Chip label={`${depositPercentage}%`} size="small" />
              )}
            </IconText>
          }
          divider
          isLoading={isLoading}
        />
      )}

      {!!order?.pre_delivery_amount && (
        <LoadableListItem
          label={<Typography variant="body1">Pre-delivery:</Typography>}
          value={
            <IconText
              startIcon={
                !!order?.pre_delivery_amount_paid_at && <CheckCircleIcon color="primary" />
              }
            >
              <Typography>{`${monetaryValues.pre_delivery_amount_paid} / ${monetaryValues.pre_delivery_amount}`}</Typography>
              {debug && !!payment_term_config && (
                <Chip label={`${preDeliveryPercentage}%`} size="small" />
              )}
            </IconText>
          }
          divider
          isLoading={isLoading}
        />
      )}

      {!!order?.final_balance_amount && (
        <LoadableListItem
          label={<Typography variant="body1">Final payment:</Typography>}
          value={
            <IconText
              startIcon={
                !!order?.final_balance_amount_paid_at && <CheckCircleIcon color="primary" />
              }
            >
              <Typography>{`${monetaryValues.final_balance_amount_paid} / ${monetaryValues.final_balance_amount}`}</Typography>
              {debug && !!payment_term_config && (
                <Chip label={`${finalPercentage}%`} size="small" />
              )}
            </IconText>
          }
          divider={false}
          isLoading={isLoading}
        />
      )}
    </List>
  );
};

export default PaymentTermsTable;

import React from "react";
import {
  Button,
  Card as MuiCard,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import {
  QUERY_KEY,
  useMutationOrderOverrideDiscountPercentage,
  useMutationOrderOverrideSoldPrice,
  useMutationOrderSetIsQuoteFinalized,
  useQueryOneOrder
} from "../../../api/Order";
import { generatePath, useNavigate, useOutletContext } from "react-router-dom";
import { OrderFormOutletContext } from "./OrderForm";
import { Formik } from "formik";
import * as Yup from "yup";
import useIsLoading from "../../../hooks/useIsLoading";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import confirm from "../../../components/Confirm";
import CartSummaryTable from "./CartSummaryTable";
import PaymentTermsTable from "./PaymentTermsTable";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { attachmentsDialog } from "../components/AttachmentsDialog.tsx";
import useHealthChecker from "../../../hooks/useHealthChecker.ts";

const Card = styled(MuiCard)(spacing);

const CartSummary: React.VFC = () => {
  const { order_uuid } = useOutletContext<Required<OrderFormOutletContext>>();
  const { data: order } = useQueryOneOrder(order_uuid, { scope: "form-view" });
  const { isSuspendMutations } = useHealthChecker();
  const { mutateAsync: overrideSoldPrice } = useMutationOrderOverrideSoldPrice();
  const { mutateAsync: overrideDiscountPercentage } = useMutationOrderOverrideDiscountPercentage();
  const { mutateAsync: setIsQuoteFinalized } = useMutationOrderSetIsQuoteFinalized();
  const isLoading = useIsLoading([QUERY_KEY]);
  const navigate = useNavigate();

  const isEmptyOrderLineItems = order?.order_line_items?.length <= 0;
  const soldPrice = order?.sold_price ?? 0;

  const validationSchema = Yup.object().shape({
    sold_price: Yup.number().positive().required().label("Total"),
    discount: Yup.number().required().min(0).max(100)
  });

  return (
    <div>
      <Formik
        initialValues={{
          sold_price: soldPrice,
          discount: order?.discount ?? 0
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={() => {
          /** Intentionally left blank **/
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values, initialValues }) => (
          <form onSubmit={handleSubmit}>
            <Tooltip title="Click the save icon to save changes" placement="top-end">
              <TextField
                name="sold_price"
                label="Total"
                value={values.sold_price ?? 0}
                error={Boolean(touched.sold_price && errors.sold_price)}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                disabled={isEmptyOrderLineItems || isLoading || isSuspendMutations}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={
                          isEmptyOrderLineItems ||
                          isLoading ||
                          Number(initialValues?.sold_price ?? 0) === Number(values.sold_price ?? 0)
                        }
                        onClick={async () => {
                          const { sold_price } = validationSchema.cast(values, {
                            stripUnknown: true
                          });

                          await overrideSoldPrice({
                            uuid: order_uuid,
                            sold_price
                          });
                        }}
                        edge="end"
                      >
                        <SaveIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>

            <Tooltip title="Click the save icon to save changes" placement="top-end">
              <TextField
                name="discount"
                label="Discount"
                value={values.discount ?? 0}
                error={Boolean(touched.discount && errors.discount)}
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                disabled={isEmptyOrderLineItems || isLoading || isSuspendMutations}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="start">%</InputAdornment>
                      <InputAdornment position="end">
                        <IconButton
                          disabled={
                            isEmptyOrderLineItems ||
                            isLoading ||
                            Number(initialValues?.discount ?? 0) === Number(values.discount ?? 0) ||
                            Boolean(touched.discount && errors.discount)
                          }
                          onClick={async () => {
                            const { discount } = validationSchema.cast(values, {
                              stripUnknown: true
                            });

                            await overrideDiscountPercentage({
                              uuid: order_uuid,
                              discount
                            });
                          }}
                          edge="end"
                        >
                          <SaveIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  )
                }}
              />
            </Tooltip>
          </form>
        )}
      </Formik>

      <Card mt={4}>
        <Typography gutterBottom variant="h6">
          Summary
        </Typography>
        <CartSummaryTable order={order} />
      </Card>

      <Card mt={4}>
        <Typography gutterBottom variant="h6">
          Payment Terms
        </Typography>
        <PaymentTermsTable order={order} />
      </Card>

      <Stack direction="row" justifyContent="space-between" spacing={3} mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={async () =>
            await attachmentsDialog({
              title: "Internal Attachments",
              is_internal: true,
              order_id: order?.id,
              order_uuid: order?.uuid
            })
          }
        >
          Attachments
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isEmptyOrderLineItems}
          onClick={async () => {
            if (
              await confirm({
                confirmation: `You are about to finalize this ${order?.order_type}. Are you sure?`,
                options: {
                  title: "Confirm"
                }
              })
            ) {
              await setIsQuoteFinalized({
                uuid: order_uuid,
                is_quote_finalized: true,
                is_quote_finalized_at: new Date()
              });
              navigate(
                generatePath("/order/:order_uuid/finalized-quote", {
                  order_uuid
                })
              );
            }
          }}
        >
          Finalize
        </Button>
      </Stack>
    </div>
  );
};

export default CartSummary;

import React, { Suspense } from "react";
import { Button, Card as MuiCard, CardContent, Grid, List, Typography } from "@mui/material";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useQueryOneOrder } from "../../../api/Order";
import {
  useMutationEditOrderLineItem,
  useQueryOrderLineItemsByOrderId
} from "../../../api/OrderLineItem";
import { FormStep2OutletContext } from "./FormStep2";
import { AccordionOrderLineItem } from "../components/AccordionOrderLineItem";
import { OrderLineItemActionsMenu } from "../components/OrderLineItemActionsMenu";
import Loader from "../../../components/Loader";

const Card = styled(MuiCard)(spacing);

const FormStep3 = () => {
  const context = useOutletContext<FormStep2OutletContext>();
  const { order_uuid, navigateToStep } = context;

  const { data: order } = useQueryOneOrder(order_uuid, { scope: "form-view" });
  const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order?.id);
  const isEmptyOrderLineItems = orderLineItems?.length <= 0;
  const { order_line_item_uuid } = useParams();
  const mutationEditOrderLineItem = useMutationEditOrderLineItem(order_line_item_uuid ?? "");

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container columnSpacing={6}>
          <Grid item xs={12} lg={6}>
            {isEmptyOrderLineItems && (
              <Card variant="outlined">
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    No items in the cart
                  </Typography>
                </CardContent>
              </Card>
            )}

            <Suspense fallback={<Loader />}>
              <List>
                {orderLineItems?.map((order_line_item: any) => {
                  return (
                    <AccordionOrderLineItem
                      key={order_line_item.uuid}
                      order_line_item={order_line_item}
                      list_item_actions={
                        <OrderLineItemActionsMenu
                          order_line_item={order_line_item}
                          order_uuid={order_uuid}
                        />
                      }
                    />
                  );
                })}
              </List>
            </Suspense>

            <Button variant="outlined" onClick={() => navigateToStep("step-2")}>
              Add item
            </Button>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Suspense fallback={<Loader />}>
              <Outlet
                context={{
                  ...context,
                  mutationOrderLineItem: mutationEditOrderLineItem
                }}
              />
            </Suspense>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FormStep3;

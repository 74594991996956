import { usePusher } from "@harelpls/use-pusher";
import { createContext, useEffect, useState } from "react";

type ConnectionStatusType =
  | "initialized"
  | "connecting"
  | "connected"
  | "unavailable"
  | "error"
  | "disconnected";

const PusherConnectionContext = createContext<
  | {
      connectionStatus: ConnectionStatusType;
      error: string | null;
      errorCode: number | null;
    }
  | undefined
>(undefined);

interface PusherConnectionProviderProps {
  children: React.ReactNode;
}

function PusherConnectionProvider({ children }: PusherConnectionProviderProps) {
  const { client: pusher } = usePusher();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatusType>("initialized");
  const [error, setError] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);

  useEffect(() => {
    if (!pusher) {
      return;
    }

    pusher.connection.bind("error", (err: any) => {
      setError(err.error?.data?.message || null);
      setErrorCode(err.error?.data?.code || null);
      setConnectionStatus("error");
      console.error("Pusher connection error:", err);
    });

    pusher.connection.bind("connecting", () => {
      setConnectionStatus("connecting");
      setError(null);
      setErrorCode(null);
    });

    pusher.connection.bind("connected", () => {
      setConnectionStatus("connected");
      setError(null);
      setErrorCode(null);
    });

    pusher.connection.bind("disconnected", () => {
      setConnectionStatus("disconnected");
    });

    pusher.connection.bind("unavailable", () => {
      setConnectionStatus("unavailable");
    });

    pusher.connection.bind("failed", () => {
      setConnectionStatus("error");
      setError("Connection failed - check your internet connection");
      setErrorCode(null);
    });
  }, [pusher]);

  return (
    <PusherConnectionContext.Provider value={{ connectionStatus, error, errorCode }}>
      {children}
    </PusherConnectionContext.Provider>
  );
}

export { PusherConnectionProvider, PusherConnectionContext };

import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import PageLoading from "../../components/PageLoading.tsx";
import { TabsFilter } from "./TabsFilter.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { PusherConnectionState } from "./PusherConnectionState.tsx";

const OrderFittingList = () => {
  useSetPageTitle("Fitting");

  return (
    <>
      <PusherConnectionState />

      <TabsFilter />

      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default OrderFittingList;

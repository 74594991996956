import { useAtom, useSetAtom } from "jotai";
import { useQueryCustomerGroups } from "../../api/CustomerGroup";
import {
  customerGroupFilterAtom,
  globalFilterAtom,
  jobStatusesFilterAtom,
  queryTypeFilterAtom
} from "../../api/OrderFitting";
import { Card, debounce, Tab, Tabs, TextField } from "@mui/material";
import { useAuthAbility } from "../../hooks/useAuthAbility.ts";
import { useEffect } from "react";
import { Stack } from "@mui/system";
import useAuth from "../../hooks/useAuth.ts";
import { USER_ROLES } from "../../constants.ts";

export const TabsFilter = () => {
  const { data: customerGroups } = useQueryCustomerGroups();

  const [customerGroupFilter, setCustomerGroupFilterParam] = useAtom(customerGroupFilterAtom);
  const [queryTypeFilter, setQueryTypeFilter] = useAtom(queryTypeFilterAtom);
  const { checkExactRolesAccess } = useAuth();
  const isFitterUser = checkExactRolesAccess([USER_ROLES.FITTER]);
  const [, setJobStatusFilter] = useAtom(jobStatusesFilterAtom);

  const setGlobalFilter = useSetAtom(globalFilterAtom);
  const handleTabsOnChange = (event: React.SyntheticEvent, value: any) => {
    const [query_type, customer_group_uuid] = value.split(":");

    setQueryTypeFilter(query_type);
    setCustomerGroupFilterParam(customer_group_uuid);
    if (query_type === "to_be_rebooked" || query_type === "back_to_rep") {
      setJobStatusFilter([query_type]);
    } else {
      setJobStatusFilter(undefined);
    }
  };

  const ability = useAuthAbility();
  const readListPendingTab = ability.can("ReadListPendingTab", "order_fitting_tab");
  const readListCombinedCustomerGroupsTab = ability.can(
    "ReadListCombinedCustomerGroupsTab",
    "order_fitting_tab"
  );
  const readListHoldTab = ability.can("ReadListHoldTab", "order_fitting_tab");
  const readListRunsTab = ability.can("ReadListRunsTab", "order_fitting_tab");
  const readListFitterTab = ability.can("ReadListFitterTab", "order_fitting_tab");

  useEffect(() => {
    if (queryTypeFilter) {
      return;
    }

    const queryType = [
      readListPendingTab && "pending",
      readListCombinedCustomerGroupsTab && "booked",
      readListHoldTab && "hold",
      readListFitterTab && "fitter"
    ].find(Boolean);

    setQueryTypeFilter(queryType);
  }, [
    queryTypeFilter,
    readListCombinedCustomerGroupsTab,
    readListFitterTab,
    readListHoldTab,
    readListPendingTab,
    setQueryTypeFilter
  ]);

  const retailCustomerGroup: any = customerGroups?.find(
    (customerGroup: any) => customerGroup.name === "Retail"
  );

  return (
    <Card sx={{ mb: 3 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
        my={{ xs: 2, md: 0 }}
        display={"flex"}
      >
        <Tabs
          value={[queryTypeFilter, customerGroupFilter].filter(Boolean).join(":")}
          onChange={handleTabsOnChange}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          <Tab
            iconPosition="end"
            label="Pending"
            value={["pending"].join(":")}
            disabled={!readListPendingTab}
          />
          <Tab
            iconPosition="end"
            label="Combined"
            value={["booked"].join(":")}
            disabled={!readListCombinedCustomerGroupsTab}
          />
          {customerGroups?.map((customerGroup: any) => {
            return (
              <Tab
                iconPosition="end"
                key={customerGroup.uuid}
                label={customerGroup.name}
                value={["booked", customerGroup.uuid].join(":")}
                disabled={!readListCombinedCustomerGroupsTab}
              />
            );
          })}
          <Tab
            iconPosition="end"
            label="Hold"
            value={["hold"].join(":")}
            disabled={!readListHoldTab}
          />
          <Tab
            iconPosition="end"
            label="Factory" // Requirements changed: refactored from Runs to Factory
            value={["runs"].join(":")}
            disabled={!readListRunsTab}
          />
          {isFitterUser && (
            <Tab
              iconPosition="end"
              label="Fitters"
              value={["fitter"].join(":")}
              disabled={!readListFitterTab}
            />
          )}

          <Tab
            iconPosition="end"
            label="Back to Rep"
            value={["back_to_rep", retailCustomerGroup.uuid].join(":")}
          />
          <Tab
            iconPosition="end"
            label="To Be Rebooked"
            value={["to_be_rebooked", retailCustomerGroup.uuid].join(":")}
          />
        </Tabs>

        <TextField
          name="search"
          placeholder="Search..."
          onChange={debounce(e => setGlobalFilter(e.target.value?.trim() || undefined), 1000)}
          type="text"
          variant="standard"
          sx={{ mx: 5 }}
        />
      </Stack>
    </Card>
  );
};

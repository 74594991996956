import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { createConfirmation } from "./ReactConfirmMountPoint";

interface Props {
  title?: string;
  description?: string;
  defaultDate?: Date;
  maxDateRange?: Date;
}

export const DatePickerDialog = ({
  show,
  proceed,
  title,
  description,
  defaultDate,
  maxDateRange
}: ConfirmDialogProps<Props, false | Date>) => {
  const handleClose = () => proceed(false);

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!!description && <DialogContentText>{description}</DialogContentText>}

        <StaticDatePicker
          showDaysOutsideCurrentMonth
          defaultValue={defaultDate}
          onClose={handleClose}
          onAccept={date => !!date && proceed(date)}
          maxDate={maxDateRange}
        />
      </DialogContent>
    </Dialog>
  );
};

export const datePickerDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<Props, false | Date>) => <DatePickerDialog {...props} />)
);

import React, { Suspense } from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Chip,
  CircularProgress as MuiCircularProgress,
  Grid,
  IconButton,
  Link as MuiLink,
  Link,
  List,
  Stack,
  Typography
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { spacing } from "@mui/system";
import { useQueryOrderLineItemsByOrderId } from "../../api/OrderLineItem";
import { generatePath, Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  QUERY_KEY,
  useMutationAcceptOrder,
  useMutationOrderSetIsInvoiced,
  useMutationOrderSetIsQuoteFinalized,
  useQueryOneOrder
} from "../../api/Order";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CartSummaryTable from "./form/CartSummaryTable";
import { Helmet } from "react-helmet-async";
import useIsLoading from "../../hooks/useIsLoading";
import confirm from "../../components/Confirm";
import { format, parseISO } from "date-fns";
import { AccordionOrderLineItem } from "./components/AccordionOrderLineItem";
import Loader from "../../components/Loader";
import { OrderFinalizedOptionsButton } from "./components/OrderFinalizedOptionsButton";
import PaymentTermsTable from "./form/PaymentTermsTable";
import CustomerDetailsCardV2 from "../customer/CustomerDetailsCardV2";
import TransactionsTable from "./form/TransactionsTable";
import { setInvoicedDateDialog } from "./components/InvoicedDateDialog";
import { Feature, useFeature } from "use-feature";
import { useQueryEvtStoreAggregate } from "../../api/EvtStore";
import { attachmentsDialog } from "./components/AttachmentsDialog.tsx";
import EventsTimelineCard from "../customer/EventLogsCard.tsx";
import { Can } from "../../casl.ts";
import { OrderProgress } from "./components/OrderProgress.tsx";
import { capitalize } from "lodash";
import {
  useMutationSetCallBackAtAndNotesLead,
  useMutationSetStatusLostLead
} from "../../api/Lead.ts";
import { setCallBackDateDialog } from "../lead/CallBackDateDialog.tsx";
import { useQueryClient } from "@tanstack/react-query";
import useLoadingBackdrop from "../../hooks/useLoadingBackdrop.ts";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const OrderQuoteFinalized = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrder(uuid, {
    scope: "finalized-view"
  });
  const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order?.id);
  const isLoading = useIsLoading();
  const navigate = useNavigate();
  const { mutateAsync: setIsQuoteFinalized } = useMutationOrderSetIsQuoteFinalized();
  const { mutateAsync: setIsInvoiced } = useMutationOrderSetIsInvoiced();

  const { mutateAsync: acceptOrder } = useMutationAcceptOrder();

  const debug = useFeature("FEATURE_DEBUG_ORDER");

  const { data: orderAgg } = useQueryEvtStoreAggregate("orders", debug ? uuid : undefined);
  const { mutateAsync: setStatusLostLead } = useMutationSetStatusLostLead();
  const { mutateAsync: setCallBackAtAndNotes } = useMutationSetCallBackAtAndNotesLead();
  const queryClient = useQueryClient();
  const loadingBackdropState = useLoadingBackdrop();

  return (
    <Stack direction="column" spacing={3} useFlexGap>
      <Helmet title={`${capitalize(order?.order_type)} ${order?.reference_number}`} />

      {Boolean(order?.is_test_mode) && (
        <Card>
          <Alert severity="warning">
            <AlertTitle>TEST ORDER</AlertTitle>
            Payments will be marked as TEST mode.
          </Alert>
        </Card>
      )}

      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Typography variant="h3" textTransform="capitalize" gutterBottom>{`${
                  order?.order_type
                } ${order?.reference_number}  ${
                  order.quote_accepted_at && !order?.is_invoiced
                    ? "Pro Forma Invoice"
                    : order?.quote_accepted_at && order?.is_invoiced
                    ? "Tax Invoice"
                    : ""
                }`}</Typography>

                {!!order?.attachments_internal?.length && ( // Check if attachments exist
                  <IconButton
                    aria-label="Attachments"
                    onClick={async () =>
                      await attachmentsDialog({
                        title: "Internal Attachments",
                        is_internal: true,
                        order_id: order?.id,
                        order_uuid: order?.uuid
                      })
                    }
                  >
                    <AttachFileIcon />
                  </IconButton>
                )}

                {isLoading && <CircularProgress mx={2} size={25} />}
              </Box>

              {!!order?.notes_reference && (
                <Typography variant="h6" gutterBottom>
                  {order?.notes_reference}
                </Typography>
              )}

              {order?.sales_rep && (
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<span>|</span>}
                  alignItems="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Typography variant="caption">
                    {`Prepared by ${order?.sales_rep?.full_name}`}
                  </Typography>
                  <Typography variant="caption">
                    {format(new Date(order?.createdAt), "dd/MM/yyyy p")}
                  </Typography>
                  {order?.sales_rep?.email && (
                    <MuiLink href={`mailto:${order?.sales_rep?.email}`} variant="caption">
                      {order?.sales_rep?.email}
                    </MuiLink>
                  )}
                  {order?.sales_rep?.phone_number && (
                    <MuiLink href={`mailto:${order?.sales_rep?.phone_number}`} variant="caption">
                      {order?.sales_rep?.phone_number}
                    </MuiLink>
                  )}
                </Stack>
              )}

              {order?.last_updated_by_user && (
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<span>|</span>}
                  alignItems="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  <Typography variant="caption">
                    {`Last edited by ${order?.last_updated_by_user?.full_name}`}
                  </Typography>

                  <Typography variant="caption">
                    {format(new Date(order?.updatedAt), "dd/MM/yyyy p")}
                  </Typography>
                </Stack>
              )}

              <Feature name="FEATURE_DEBUG_ORDER">
                <Stack
                  mt={3}
                  direction="row"
                  spacing={2}
                  divider={<span>|</span>}
                  alignItems="center"
                  useFlexGap
                  flexWrap="wrap"
                >
                  {!!order?.is_qbcc && <Chip label="QBCC" size="small" />}
                  {!!order?.is_check_measure && <Chip label="Check Measure" size="small" />}
                </Stack>
              </Feature>
            </Box>

            {order?.quote_accepted_at ? (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h4">Order Accepted</Typography>
                  <Typography variant="caption">
                    {format(new Date(order?.quote_accepted_at), "dd/MM/yyyy p")}
                  </Typography>

                  <Typography variant="caption">
                    {[
                      order?.deposit_amount_paid_at
                        ? "Deposit Processed"
                        : order?.quote_deposit_payment_initiated_at
                        ? "Deposit Pending"
                        : "",
                      !!order?.quote_deposit_payment_initiated_using &&
                        ` (${order?.quote_deposit_payment_initiated_using})`
                    ]
                      .filter(v => v)
                      .join(" ")
                      .trim()}
                  </Typography>
                </Box>
                <Button variant="contained" color="primary" disabled>
                  Check Measure
                </Button>
              </>
            ) : (
              <Can I="AcceptOrder" a="order">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    if (
                      await confirm({
                        confirmation: "You are about to mark this Accepted. Are you sure?",
                        options: {
                          title: "Confirm"
                        }
                      })
                    ) {
                      loadingBackdropState.increment();

                      uuid &&
                        (await acceptOrder(uuid).finally(() => loadingBackdropState.decrement()));
                    }
                  }}
                >
                  Accept Order
                </Button>
              </Can>
            )}

            <Stack direction="column" alignItems="start" useFlexGap flexWrap="wrap">
              <Stack
                direction="row"
                spacing={3}
                justifyContent="space-between"
                alignItems="center"
                useFlexGap
                flexWrap="wrap"
              >
                {order?.quote_accepted_at && uuid && (
                  <Can I="SetIsInvoiced" a="order">
                    <Button
                      variant="outlined"
                      startIcon={order?.is_invoiced && <CheckCircleIcon color="primary" />}
                      onClick={async () => {
                        if (!order?.is_invoiced) {
                          const result = await setInvoicedDateDialog({
                            uuid
                          });

                          if (result !== false) {
                            loadingBackdropState.increment();

                            await setIsInvoiced({
                              uuid,
                              is_invoiced: true,
                              is_invoiced_at: parseISO(result.is_invoiced_at)
                            }).finally(() => loadingBackdropState.decrement());
                          }
                        } else {
                          if (
                            await confirm({
                              confirmation: `You are about to mark this as not invoiced. Are you sure?`,
                              options: {
                                title: "Confirm"
                              }
                            })
                          ) {
                            loadingBackdropState.increment();

                            await setIsInvoiced({
                              uuid,
                              is_invoiced: false,
                              is_invoiced_at: null
                            }).finally(() => loadingBackdropState.decrement());
                          }
                        }
                      }}
                    >
                      Invoice
                    </Button>
                  </Can>
                )}

                {!order?.quote_accepted_at && (
                  <>
                    {!!order?.lead && (
                      <>
                        <Can I="SetCallBackAtAndNotes" a="lead">
                          <Stack direction="column">
                            <Button
                              variant="outlined"
                              onClick={async () => {
                                const values = await setCallBackDateDialog({
                                  uuid: order?.lead?.uuid,
                                  call_back_at: order?.lead?.call_back_at,
                                  call_back_notes: order?.lead?.call_back_notes
                                });

                                if (values !== false) {
                                  loadingBackdropState.increment();

                                  await setCallBackAtAndNotes(values).finally(() =>
                                    loadingBackdropState.decrement()
                                  );
                                  await queryClient.invalidateQueries([QUERY_KEY]);
                                }
                              }}
                            >
                              Set Call Back
                            </Button>
                            {/*{order?.lead?.call_back_at && (*/}
                            {/*  <Typography variant="caption">*/}
                            {/*    {format(new Date(order?.lead?.call_back_at), "dd/MM/yyyy")}*/}
                            {/*  </Typography>*/}
                            {/*)}*/}
                          </Stack>
                        </Can>

                        <Can I="SetStatusLost" a="lead">
                          <Button
                            variant="outlined"
                            color="warning"
                            disabled={order?.lead?.is_lost_status}
                            onClick={async () => {
                              if (
                                await confirm({
                                  confirmation:
                                    "You are about to mark the lead status as Lost. Are you sure?",
                                  options: {
                                    title: "Confirm"
                                  }
                                })
                              ) {
                                loadingBackdropState.increment();

                                await setStatusLostLead({
                                  uuid: order?.lead?.uuid
                                })
                                  .then(() => queryClient.invalidateQueries([QUERY_KEY]))
                                  .finally(() => loadingBackdropState.decrement());
                              }
                            }}
                          >
                            Lost
                          </Button>
                        </Can>
                      </>
                    )}

                    <Can I="SetIsQuoteFinalized" a="order">
                      <Button
                        variant="outlined"
                        onClick={async () => {
                          if (
                            await confirm({
                              confirmation: `You are about to enable editing of this ${order?.order_type}. It will be marked as not finalized. Are you sure?`,
                              options: {
                                title: "Confirm"
                              }
                            })
                          ) {
                            loadingBackdropState.increment();

                            await setIsQuoteFinalized({
                              uuid,
                              is_quote_finalized: false,
                              is_quote_finalized_at: null
                            }).finally(() => loadingBackdropState.decrement());

                            navigate(
                              generatePath("/order/:uuid/edit/step-3", {
                                uuid: uuid ?? null
                              })
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </Can>
                  </>
                )}

                <OrderFinalizedOptionsButton />
              </Stack>
              {order?.is_invoiced && (
                <Typography variant="caption">
                  {format(new Date(order?.is_invoiced_at), "dd/MM/yyyy p")}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <Suspense fallback={<Loader />}>
                <List>
                  {orderLineItems?.map((order_line_item: any) => {
                    return (
                      <AccordionOrderLineItem
                        key={order_line_item.uuid}
                        order_line_item={order_line_item}
                      />
                    );
                  })}
                </List>
              </Suspense>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={3} useFlexGap>
            <Card>
              <CustomerDetailsCardV2
                site_address={order?.site_address}
                customer_uuid={order?.customer?.uuid}
                hideMoreOptionsButton
                hideCreateOrderFromButton
              />
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Summary
                </Typography>

                <CartSummaryTable order={order} />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Payment Terms
                </Typography>

                {!!orderAgg?.payment_term_config?.name && (
                  <Link
                    component={RouterLink}
                    underline="none"
                    target="_blank"
                    to={generatePath("/payment-term-config/:uuid/edit", {
                      uuid: orderAgg?.payment_term_config?.uuid
                    })}
                  >
                    <Chip label={orderAgg?.payment_term_config?.name} size="small" sx={{ mx: 1 }} />
                  </Link>
                )}
                {!!orderAgg?.payment_term_config?.attach_qbcc_contract && (
                  <Chip label="Attach QBCC" size="small" sx={{ mx: 1 }} />
                )}

                <PaymentTermsTable
                  payment_term_config={orderAgg?.payment_term_config}
                  order={order}
                />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <TransactionsTable disablePaymentButton={!order?.quote_accepted_at} order={order} />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <Card>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography>Order Progress:</Typography>
          <OrderProgress status={order?.status_v2} />
        </CardContent>
      </Card>

      {debug && (
        <Can I="Read" a="event_store">
          <EventsTimelineCard stream="orders" aggregate_id={uuid} />
        </Can>
      )}
    </Stack>
  );
};

export default OrderQuoteFinalized;

import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";
import { useQueryOneOrder } from "../../../api/Order.ts";
import { capitalize } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import asyncComponent from "../../../components/Async.tsx";

const OrderFinalizedPDFViewer = asyncComponent(
  () => import("../pdf/order_finalized/OrderFinalizedPDFViewer")
);

export interface OrderFinalizedPDFDialogProps {
  order_uuid: string;
  is_public?: boolean;
}

export const OrderFinalizedPDFDialog = ({
  show,
  proceed,
  order_uuid,
  is_public
}: ConfirmDialogProps<OrderFinalizedPDFDialogProps, false>) => {
  const handleClose = () => proceed(false);
  const { data: order } = useQueryOneOrder(order_uuid, {
    scope: "finalized-view",
    is_public_view: is_public
  });

  return (
    <Dialog open={show} onClose={handleClose} fullScreen>
      <DialogTitle>{`${capitalize(order?.order_type)} ${order?.reference_number}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <OrderFinalizedPDFViewer order={order} public_view={is_public} />
      </DialogContent>
    </Dialog>
  );
};

export const orderFinalizedPDFDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<OrderFinalizedPDFDialogProps, false>) => (
    <OrderFinalizedPDFDialog {...props} />
  ))
);

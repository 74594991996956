import React from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import useFormikSubmitHandler from "../../../hooks/useFormikSubmitHandler";
import { Formik, useFormikContext } from "formik";
import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  Card as MuiCard,
  CardContent,
  Stack,
  TextField
} from "@mui/material";
import AutocompleteField from "../../../components/AutocompleteField";
import { get } from "lodash";
import {
  Refresh as RefreshIcon,
  Save as SaveIcon,
  SkipNext as SkipNextIcon
} from "@mui/icons-material";
import { QUERY_KEY, useQueryOneOrder, useQueryOrderFormOptions } from "../../../api/Order";
import useIsLoading from "../../../hooks/useIsLoading";
import useHealthChecker from "../../../hooks/useHealthChecker";
import qs from "qs";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import requiredHelpText from "../../../utils/requiredHelpText";
import GooglePlacesInput from "../../../components/GooglePlacesInput";
import { OrderFormOutletContext } from "./OrderForm";
import Grid from "@mui/material/Unstable_Grid2";
import CustomerDetailsCardV2 from "../../customer/CustomerDetailsCardV2";
import { Feature } from "use-feature";
import { AutocompleteInfiniteQueryField } from "../../../components/AutocompleteInfiniteQueryField.tsx";
import { useQueryOneCustomer } from "../../../api/Customer.ts";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

interface Values {
  lead_uuid?: string;
  customer_uuid?: string;
  order_type?: string;
  sales_rep_uuid?: string;
  date?: string;
  site_address?: string;
  notes_reference?: string;
}

const FormStep1 = () => {
  const { mutationOrder, order_uuid, navigateToStep } = useOutletContext<OrderFormOutletContext>();
  const { data: formOptions } = useQueryOrderFormOptions();
  const { data: order } = useQueryOneOrder(order_uuid, { scope: "form-view" });
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();

  const [searchParams] = useSearchParams();
  const parsedSearchParams = qs.parse(searchParams.toString());

  const validationSchema = Yup.object().shape({
    lead_uuid: Yup.string().uuid().optional().nullable().label("Lead"),
    customer_uuid: Yup.string().uuid().required().label("Customers"),
    order_type: Yup.string()
      .optional()
      .nullable()
      .label("Order Type")
      .oneOf([null, ...(formOptions?.order_types ?? [])]),
    sales_rep_uuid: Yup.string()
      .uuid()
      .required()
      .label("Sales Rep")
      .oneOf(formOptions?.sales_rep_users?.map(({ uuid }: any) => uuid) ?? []),
    site_address: Yup.string().max(255).optional().nullable(),
    notes_reference: Yup.string().max(255).optional().nullable()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation: mutationOrder,
    navigate_to: "/order",
    validationSchema,
    mutateOptions: {
      onSuccess: response => navigateToStep("step-2", response.uuid)
    }
  });

  const createdFromLead = !!order?.lead_uuid;

  return (
    <Formik
      initialValues={{
        lead_uuid: null,
        customer_uuid: null,
        sales_rep_uuid: null,
        site_address: "",
        notes_reference: "",
        ...(parsedSearchParams?.initialValues as Record<string, string>),
        ...order
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, dirty, setValues, touched, errors, handleBlur, handleChange }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid xs={12} lg={8}>
                  {createdFromLead && (
                    <AutocompleteInfiniteQueryField
                      label="Lead"
                      name="lead_uuid"
                      queryKeyApi="lead"
                      initialQueryParams={{ sort: "lead_number" }}
                      mapRowToOption={(option: any) => ({
                        id: option.uuid,
                        label: option.lead_number.toString()
                      })}
                      fullWidth
                      openOnFocus
                      disabled={isLoading || createdFromLead}
                    />
                  )}

                  <AutocompleteField
                    label="Type"
                    name="order_type"
                    options={
                      formOptions?.order_types?.map((item: string) => ({
                        label: item,
                        id: item
                      })) ?? []
                    }
                    fullWidth
                    openOnFocus
                    disabled={isLoading}
                  />

                  <AutocompleteField
                    label="Sales Rep"
                    name="sales_rep_uuid"
                    options={formOptions?.sales_rep_users?.map((item: any) => ({
                      label: item.email,
                      id: item.uuid
                    }))}
                    fullWidth
                    openOnFocus
                    disabled={isLoading}
                  />

                  <TextField
                    name="notes_reference"
                    label="Notes Reference"
                    value={values.notes_reference ?? ""}
                    error={Boolean(touched.notes_reference && errors.notes_reference)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    disabled={isLoading}
                  />
                </Grid>
                <Grid xs={12} lg={4}>
                  <CustomerWarningMessage />

                  <AutocompleteInfiniteQueryField
                    label="Customer"
                    name="customer_uuid"
                    queryKeyApi="customers"
                    initialQueryParams={{ sort: "name" }}
                    mapRowToOption={(option: any) => ({
                      id: option.uuid,
                      label: `${option.full_name_with_company} (${option.email})`
                    })}
                    fullWidth
                    openOnFocus
                    readOnly={!!get(parsedSearchParams, "initialValues.customer_uuid")}
                    disabled={isLoading}
                  />

                  {!!values.customer_uuid && (
                    <Card variant="outlined">
                      <CustomerDetailsCardV2
                        site_address={values?.site_address}
                        customer_uuid={values.customer_uuid}
                        hideMoreOptionsButton
                        hideCreateOrderFromButton
                      />
                    </Card>
                  )}

                  <GooglePlacesInput
                    label="Site Address"
                    name="site_address"
                    helpText={requiredHelpText("site_address", validationSchema)}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>

              <Stack direction="row" justifyContent="flex-end" spacing={3} mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isLoading || isSuspendMutations}
                  startIcon={<SaveIcon />}
                >
                  Save & Next
                </Button>

                {order_uuid && (
                  <Button
                    onClick={() => navigateToStep("step-2")}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    startIcon={<SkipNextIcon />}
                  >
                    Next
                  </Button>
                )}

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => {
                        return {
                          ...prevValues,
                          // customer_uuid: faker.helpers.arrayElement(
                          //   formOptions?.customers?.map(({ uuid }: any) => uuid)
                          // ),
                          order_type: faker.helpers.arrayElement(formOptions?.order_types ?? []),
                          sales_rep_uuid: faker.helpers.arrayElement(
                            formOptions?.sales_rep_users?.map(({ uuid }: any) => uuid)
                          ),
                          ...(parsedSearchParams?.initialValues as Record<string, string>)
                        };
                      });
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Stack>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default FormStep1;

const CustomerWarningMessage = () => {
  const { values } = useFormikContext<any>();
  const { data: customer } = useQueryOneCustomer(values?.customer_uuid);

  if (!customer?.is_tester) {
    return null;
  }

  return (
    <Alert severity="warning">
      <AlertTitle>TEST ORDER</AlertTitle>
      Payments will be marked as TEST mode.
    </Alert>
  );
};

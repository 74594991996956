import {
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import {
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query/src/types";
import { useSnackbar } from "notistack";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import { isEmpty, negate } from "lodash";
import { InvalidateQueryFilters, Query } from "@tanstack/query-core";
import { AxiosError, AxiosRequestConfig } from "axios";
import { createErrorInfo } from "../utils/createErrorInfo";
import { useChannel } from "@harelpls/use-pusher";
import { useEventHandledPromise } from "./useEventHandledPromise.ts";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

export type MutationOptionsType<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
  invalidateQueryKey?: QueryKey | InvalidateQueryFilters;
  onSuccessMsg?: string;
  doNotShowSuccessMsg?: boolean;
};

export type MutationResultType<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
> = UseMutationResult<TData, TError, TVariables, TContext>;

export function useMutationAbstract<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(mutationOptions: MutationOptionsType<TData, TError, TVariables, TContext>) {
  const { invalidateQueryKey, onSuccessMsg, doNotShowSuccessMsg, ...theRest } = mutationOptions;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<TData, TError, TVariables, TContext>({
    onSuccess: async () => {
      if (invalidateQueryKey) {
        // @ts-expect-error quick
        await queryClient.invalidateQueries(invalidateQueryKey);
      }
    },
    useErrorBoundary: error => {
      const responseStatus = (error as AxiosError)?.response?.status ?? 500;
      return responseStatus >= 500;
    },
    onError: error => {
      const { errorCode, errorMessage } = createErrorInfo(error as AxiosError);

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
        key: errorCode
      });
    },
    onSettled: (data, error) => {
      if (!error) {
        !doNotShowSuccessMsg &&
          enqueueSnackbar(onSuccessMsg ?? "Success", {
            variant: "success",
            preventDuplicate: true
          });
      }
    },
    ...theRest
  });
}

export function useQueryModels<T = object[], TError = unknown>(
  key: string,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
): UseQueryResult<T, TError> {
  return useQueryModelsV2(key, undefined, options, requestConfig);
}

export function useQueryModelsV2<T = object[], TError = unknown>(
  key: string,
  query?: any,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
): UseQueryResult<T, TError> {
  return useQuery<T, TError>({
    queryKey: [key, "list", query].filter(negate(isEmpty)),
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${key}`, {
          signal,
          params: query,
          ...requestConfig
        })
        .then(({ data }) => data),
    ...options
  });
}

export function useQueryOneModel<T, TError = unknown>(
  key: string,
  uuid?: string,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
): UseQueryResult<T, TError> {
  return useQuery<T, TError>({
    enabled: !!uuid,
    queryKey: [key, "one", uuid, requestConfig],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${key}/${uuid}`, {
          signal,
          ...requestConfig
        })
        .then(({ data }) => data),
    retry: 2,
    ...options
  });
}

export function useQueryFormOptionsModel<T, TError = unknown>(
  key: string,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
): UseQueryResult<T, TError> {
  return useQuery<T, TError>({
    queryKey: [key, "form-options"],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${key}/form-options`, {
          signal,
          ...requestConfig
        })
        .then(({ data }) => data),
    staleTime: 30000,
    meta: { cache: false },
    ...options
  });
}

export const useMutationAddModel = (key: string, uuid: string) => {
  return useMutationAddModelV2<any>(key, uuid);
};

export function useMutationAddModelV2<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  uuid: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key],
    mutationFn: data =>
      axios
        .post<TData>(
          `/api/${key}`,
          {
            ...data,
            uuid
          },
          {
            headers: {
              "Idempotency-Key": `add-${uuid}`
            }
          }
        )
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export function useMutationEditModel<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  uuid: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key, uuid],
    mutationFn: data =>
      axios
        .put<TData>(`/api/${key}/${uuid}`, {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export function useMutationPatchModel<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  uuid: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key, uuid],
    mutationFn: data =>
      axios
        .patch<TData>(`/api/${key}/${uuid}`, {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export function useMutationPatchModelV2<
  TData = { uuid: string; [index: string]: any },
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key],
    // TODO: Fix the any type here
    mutationFn: ({ uuid, ...data }: any) =>
      axios
        .patch<TData>(`/api/${key}/${uuid}`, {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export function useMutationDeleteModel<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  key: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key],
    mutationFn: uuid =>
      axios
        .delete<TData>(`/api/${key}/${uuid}`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export function useMutationDeleteModelV2<
  TData = unknown,
  TError = unknown,
  TVariables = Partial<TData>,
  TContext = unknown
>(
  key: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key],
    mutationFn: uuid => {
      return axios
        .delete<TData>(`/api/${key}/${uuid}`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        });
    },
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export interface BulkDeleteModelType {
  uuid: string;
  deleted: boolean;
  message: string;
  constraintInfo?: { TABLE_NAME: string; COLUMN_NAME: string };
  rows?: any[];
}

export function useMutationBulkDeleteModelV2<
  TData extends BulkDeleteModelType[],
  TError = unknown,
  TVariables = GridRowSelectionModel,
  TContext = unknown
>(key: string) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    doNotShowSuccessMsg: true,
    mutationKey: [key],
    mutationFn: uuids =>
      axios
        .delete(`/api/${key}/bulk-delete`, { data: { uuids } })
        .then(({ data }) => data)
        .then(data => {
          // we have to do it this way to wait for event handlers to process changes
          return sleep(1500, data);
        }),

    onSuccess: async results => {
      const resultsNotDeleted = results?.filter(result => !result.deleted);
      const resultsDeleted = results?.filter(result => result.deleted);

      resultsDeleted.forEach(({ uuid, message }) => {
        queryClient.removeQueries([key, "one", uuid]);

        enqueueSnackbar(message, {
          variant: "success",
          preventDuplicate: true,
          key: uuid
        });
      });

      resultsNotDeleted.forEach(({ uuid, message }) => {
        enqueueSnackbar(message, {
          variant: "error",
          preventDuplicate: true,
          key: uuid
        });
      });

      await queryClient.invalidateQueries([key]);
    }
  });
}

export function useMutationRestoreDeletedModel<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  key: string,
  options?: MutationOptionsType<TData, TError, TVariables, TContext> & {
    pusherChannelName?: string;
  }
) {
  const { pusherChannelName, ...mutationOptions } = options ?? {};
  const channel = useChannel(pusherChannelName);
  const eventHandledPromise = useEventHandledPromise<TData>(channel);

  return useMutationAbstract<TData, TError, TVariables, TContext>({
    mutationKey: [key],
    mutationFn: uuid =>
      axios
        .post<TData>(`/api/${key}/${uuid}/restore-deleted`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<TData>([
            eventHandledPromise(uuid as string, data),
            sleep(1500, data)
          ]);
        }),
    invalidateQueryKey: [key],
    ...mutationOptions
  });
}

export const predicateKeyList = (key: string) => (query: Query) => {
  const [queryKey, type] = query.queryKey;
  if (type !== "list") {
    return false;
  }

  return queryKey === key;
};

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";

interface Props {}

interface Output {
  customer_name: string;
}

export const OrderCompleteDialog = confirmable(
  ({ show, proceed }: ConfirmDialogProps<Props, false | Output>) => {
    const handleClose = () => proceed(false);

    const validationSchema = Yup.object().shape({
      customer_name: Yup.string().required().label("Customer Name")
    });

    const formik = useFormik({
      initialValues: {
        customer_name: ""
      },
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        const validated = validationSchema.cast(values, {
          stripUnknown: true
        });
        proceed(validated);
      }
    });

    const { errors, handleBlur, handleChange, handleSubmit, touched, values } = formik;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            My order has been completed to my satisfaction
          </DialogTitle>
          <DialogContent>
            <TextField
              name="customer_name"
              label="Name"
              placeholder="Enter your name to confirm"
              value={values.customer_name}
              error={Boolean(touched.customer_name && errors.customer_name)}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              autoFocus
              disabled={Boolean(touched.customer_name && errors.customer_name)}
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export const orderCompleteConfirmationDialog = createConfirmation(OrderCompleteDialog);

import Uppy, { SuccessResponse, UploadSuccessCallback, UppyFile, UppyOptions } from "@uppy/core";
import { DashboardModal } from "@uppy/react";
import React, { useCallback, useEffect, useRef } from "react";
import { v4 } from "uuid";
import { DashboardModalProps } from "@uppy/react/src/DashboardModal";
import XHRUpload from "@uppy/xhr-upload";
import axios from "../utils/axios";
import { apiBaseURL } from "../config.ts";

const UploaderModal: React.VFC<
  {
    uppyOptions?: Partial<UppyOptions>;
    onUploadSuccess?: UploadSuccessCallback<any>;
  } & Omit<DashboardModalProps, "uppy">
> = ({ uppyOptions, onUploadSuccess, ...dashboardModalProps }) => {
  const uppyRef = useRef(
    new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 20,
        // 64 mb
        maxFileSize: 64000000
      }
    }).use(XHRUpload, {
      endpoint: `${apiBaseURL}/api/file/upload`,
      headers: {
        Authorization: axios.defaults.headers.common.Authorization as string
      },
      getResponseData: responseText => {
        const { url } = JSON.parse(responseText);

        if (!url) {
          return { url: null };
        }

        return {
          url: url.startsWith("https://") ? url : `https://${url}`
        };
      }
    })
  );

  useEffect(() => {
    if (uppyOptions) {
      uppyRef.current.setOptions(uppyOptions);
    }
  }, [uppyOptions]);

  const onFileAdded = useCallback((file: UppyFile) => {
    uppyRef.current.setFileMeta(file.id, {
      uuid: v4()
    });
  }, []);

  const _onUploadSuccess = useCallback(
    (file: UppyFile | undefined, response: SuccessResponse) => {
      if (!file) {
        return;
      }

      onUploadSuccess && onUploadSuccess(file, response);
    },
    [onUploadSuccess]
  );

  useEffect(() => {
    uppyRef.current.on("file-added", onFileAdded);
    uppyRef.current.on("upload-success", _onUploadSuccess);

    // Fixes: The ref value 'uppyRef.current' will likely have changed by the time this effect cleanup function runs. If this ref points to a node rendered by React, copy 'uppyRef.current' to a variable inside the effect, and use that variable in the cleanup function
    const uppyRefCurrent = uppyRef.current;

    return () => {
      uppyRefCurrent.off("file-added", onFileAdded);
      uppyRefCurrent.off("upload-success", _onUploadSuccess);
    };
  }, [onFileAdded, _onUploadSuccess]);

  return (
    <DashboardModal
      uppy={uppyRef.current}
      proudlyDisplayPoweredByUppy={false}
      showLinkToFileUploadResult
      showProgressDetails
      {...dashboardModalProps}
    />
  );
};

export default UploaderModal;

import { useContext } from "react";
import { PusherConnectionContext } from "../contexts/PusherConnectionContext.tsx";

export const usePusherConnection = () => {
  const context = useContext(PusherConnectionContext);

  if (!context)
    throw new Error("PusherConnectionContext must be placed within PusherConnectionProvider");

  return context;
};

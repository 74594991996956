import { chain } from "lodash";
import { pportalOrdersBaseURL } from "../config.ts";
import qs from "qs";
import { differenceInMinutes } from "date-fns";
import { subtract } from "dinero.js";
import { dineroAUDFromFloat, toDecimalNumber } from "aldrin-erp-main-shared-functions";

export const usePaymentUrlForTransaction = (transaction_for: string, order: any, company: any) => {
  const transactionForMapping: Record<string, any> = {
    deposit: [
      toDecimalNumber(
        subtract(
          dineroAUDFromFloat(order?.deposit_amount),
          dineroAUDFromFloat(order?.deposit_amount_paid || 0)
        ).toJSON()
      ),
      order?.deposit_amount_paid_at
    ],
    "pre-delivery": [
      toDecimalNumber(
        subtract(
          dineroAUDFromFloat(order?.pre_delivery_amount),
          dineroAUDFromFloat(order?.pre_delivery_amount_paid || 0)
        ).toJSON()
      ),
      order?.pre_delivery_amount_paid_at
    ],
    final: [
      toDecimalNumber(
        subtract(
          dineroAUDFromFloat(order?.final_balance_amount),
          dineroAUDFromFloat(order?.final_balance_amount_paid || 0)
        ).toJSON()
      ),
      order?.final_balance_amount_paid_at
    ]
  };

  const [amount, paid_at] = transactionForMapping[transaction_for] ?? [];

  const initiatedPayment = chain(order?.transactions)
    .filter(tran => tran.transaction_for === transaction_for)
    .filter(tran => tran.status === "initiated")
    .filter(tran => {
      const now = new Date();
      const transactionDate = new Date(tran.date);
      return differenceInMinutes(now, transactionDate) < 20;
    })
    .orderBy("id", "desc")
    .first()
    .value();

  if (!amount || paid_at) {
    return null;
  }

  if (initiatedPayment?.metadata) {
    return `${pportalOrdersBaseURL}/payment${qs.stringify(
      {
        customerInfo: {
          firstName: initiatedPayment?.metadata?.firstName,
          lastName: initiatedPayment?.metadata?.firstName,
          email: initiatedPayment?.metadata?.email,
          merchantReference: initiatedPayment?.metadata?.merchantReference,
          authKey: initiatedPayment?.metadata?.authkey,
          totalQuoteAmount: initiatedPayment?.metadata?.totalQuoteAmount,
          testMode: initiatedPayment?.metadata?.TestMode,
          testModeReason: initiatedPayment?.metadata?.TestModeReason,
          order_uuid: order?.uuid,
          amount,
          transaction_for,
          company_image: company?.image
        }
      },
      { addQueryPrefix: true }
    )}`;
  }

  return `${pportalOrdersBaseURL}${qs.stringify(
    {
      initialValues: {
        firstName: order?.customer?.first_name,
        lastName: order?.customer?.last_name,
        email: order?.customer?.email,
        merchantReference: order?.reference_number,
        order_uuid: order?.uuid,
        is_test_mode: order?.is_test_mode,
        totalQuoteAmount: order?.sold_price,
        amount,
        transaction_for,
        company_image: company?.image
      }
    },
    { addQueryPrefix: true }
  )}`;
};

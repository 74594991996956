import { useEffect, useRef } from "react";
import { openobserveRum, RumEvent } from "@openobserve/browser-rum";
import { LogsEvent, openobserveLogs } from "@openobserve/browser-logs";
import { openobserveDisabled, openobserveRumInit } from "../config.ts";

const options = {
  ...openobserveRumInit,
  applicationId: "aerp-main-frontend",
  service: "aerp-main-frontend",
  env: import.meta.env.MODE,
  version: BUILD_VERSION,
  insecureHTTP: import.meta.env.MODE === "development",
  apiVersion: "v1"
};

export const useOpenObserveInit = () => {
  const initialized = useRef(false);
  useEffect(() => {
    if (openobserveDisabled || !options.clientToken || initialized.current) {
      return;
    }

    openobserveRum.init({
      applicationId: options.applicationId, // required, any string identifying your application
      clientToken: options.clientToken,
      site: options.site,
      organizationIdentifier: options.organizationIdentifier,
      service: options.service,
      env: options.env,
      version: options.version,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      apiVersion: options.apiVersion,
      insecureHTTP: options.insecureHTTP,
      defaultPrivacyLevel: "allow", // 'allow' or 'mask-user-input' or 'mask'. Use one of the 3 values.,
      beforeSend: (event: RumEvent) => {
        if (event.type === "error") {
          if (
            [
              "console error: AxiosError: Request failed with status code 401",
              "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.", // unnecessary since I have to remove persistQueryClient implementation anyway
              "Interleaved load operation detected" // thrown by Bryntum calendar - no solution found.
            ].includes(event?.error?.message ?? "")
          ) {
            console.debug("RUM skip", event?.error?.message ?? "", event);
            return false;
          }

          if (event?.error?.message?.startsWith("console error: Pusher connection error:")) {
            const JSONString = event?.error?.message?.replace(
              "console error: Pusher connection error: ",
              ""
            );
            try {
              const parsedData = JSON.parse(JSONString);
              console.debug("Pusher connection error", event, parsedData);

              const error_code = parsedData?.error?.data?.code ?? parsedData?.data?.code;

              if (error_code === 1006) {
                console.debug("RUM skip", "Pusher error code is 1006", parsedData);
                return false;
              }

              if (error_code >= 4200 && error_code <= 4299) {
                console.debug("RUM skip", "Pusher error code between 4200 and 4299", parsedData);
                return false;
              }
            } catch (error) {
              console.error("Failed to parse Pusher connection error JSON", JSONString, error);
            }
          }
        }
      }
    });

    openobserveLogs.init({
      clientToken: options.clientToken,
      site: options.site,
      organizationIdentifier: options.organizationIdentifier,
      service: options.service,
      env: options.env,
      version: options.version,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ["error", "info", "log"],
      insecureHTTP: options.insecureHTTP,
      apiVersion: options.apiVersion,
      beforeSend: (event: LogsEvent) => {
        const urlPath = event?.view?.url
          ? new URL(event.view.url, window.location.origin).pathname
          : "";

        if (
          ["/lead-calendar", "/order-fitting-calendar"].includes(urlPath) &&
          event?.error?.stack?.startsWith("AbortError: signal is aborted without reason")
        ) {
          console.debug("RUM log skip", "AbortError: signal is aborted without reason", event);
          return false;
        }

        if (
          [
            "console error: AxiosError: Request failed with status code 401",
            "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.", // unnecessary since I have to remove persistQueryClient implementation anyway
            "Interleaved load operation detected" // thrown by Bryntum calendar - no solution found.
          ].includes(event?.message ?? "")
        ) {
          console.debug("RUM log skip", event?.message ?? "", event);
          return false;
        }

        if (event?.message?.startsWith("console error: Pusher connection error:")) {
          const JSONString = event?.message?.replace(
            "console error: Pusher connection error: ",
            ""
          );
          try {
            const parsedData = JSON.parse(JSONString);
            console.debug("Pusher connection error", event, parsedData);

            const error_code = parsedData?.error?.data?.code ?? parsedData?.data?.code;

            if (error_code === 1006) {
              console.debug("RUM skip", "Pusher error code is 1006", parsedData);
              return false;
            }

            if (error_code >= 4200 && error_code <= 4299) {
              console.debug("RUM log skip", "Pusher error code between 4200 and 4299", parsedData);
              return false;
            }
          } catch (error) {
            console.error("Failed to parse Pusher connection error JSON", JSONString, error);
          }
        }
      }
    });

    initialized.current = true;
  }, []);
};

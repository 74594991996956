import React, { Suspense } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";
import Loader from "../../../components/Loader";
import { useQueryFiles } from "../../../api/File.ts";
import { filesize } from "filesize";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useMutationAddFileAttachmentOrder,
  useMutationDeleteAttachment
} from "../../../api/Order.ts";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { SuccessResponse, UppyFile } from "@uppy/core";
import AddIcon from "@mui/icons-material/Add";
import UploaderModal from "../../../components/UploaderModal.tsx";
import useHealthChecker from "../../../hooks/useHealthChecker.ts";
import confirm from "../../../components/Confirm.tsx";

export interface AttachmentDialogProps {
  title: string;
  description?: string;
  order_id?: number;
  order_uuid?: string;
  is_internal?: boolean;
  is_public?: boolean;
  all_attachments?: boolean;
}

export const AttachmentsDialog = ({
  show,
  proceed,
  title,
  description,
  order_id,
  order_uuid,
  is_internal,
  all_attachments
}: ConfirmDialogProps<AttachmentDialogProps, false>) => {
  const { isSuspendMutations } = useHealthChecker();
  const handleClose = () => proceed(false);
  const { data: paginatedFiles } = useQueryFiles(
    all_attachments
      ? {
          model_name: "OrderModel",
          model_id: order_id,
          page: 1,
          limit: 100
        }
      : {
          model_name: "OrderModel",
          model_id: order_id,
          is_internal: is_internal ? 1 : 0,
          page: 1,
          limit: 100
        }
  );
  const files = paginatedFiles?.rows;

  const { mutate: addFileAttachment } = useMutationAddFileAttachmentOrder();
  const { mutate: deleteAttachment } = useMutationDeleteAttachment();

  const [openUploadModal, setOpenUploadModal] = useQueryParam(
    "open-upload-modal",
    withDefault(BooleanParam, false)
  );

  const onUploadSuccess = (file: UppyFile | undefined, response: SuccessResponse) => {
    !!file &&
      addFileAttachment({
        order_uuid,
        uuid: file.meta.uuid,
        name: file.name,
        size_in_bytes: file.data.size,
        file_type: file.type,
        location_url: response.uploadURL,
        is_internal
      });
  };

  const handleDeleteAttachment = async (attachmentId: string | undefined) => {
    if (
      await confirm({
        confirmation: "You are about to delete this attachment. Are you sure?",
        options: {
          title: "Confirm"
        }
      })
    ) {
      !!attachmentId && deleteAttachment({ uuid: attachmentId, order_uuid: order_uuid });
    }
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth={"lg"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!!description && <DialogContentText>{description}</DialogContentText>}

        <List dense>
          {!files?.length && (
            <ListItem>
              <ListItemText primary={"No attachments"} />
            </ListItem>
          )}

          {files?.map((attached: any) => (
            <ListItem
              key={attached.id}
              secondaryAction={
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteAttachment(attached.uuid)}
                    disabled={isSuspendMutations}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton component="a" target="_blank" href={attached.location_url}>
                <ListItemAvatar>
                  <Avatar>
                    <AttachFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={attached.name}
                  secondary={filesize(attached.size_in_bytes, {
                    standard: "jedec"
                  })}
                />
              </ListItemButton>
            </ListItem>
          ))}

          {!all_attachments && (
            <ListItem>
              <ListItemButton
                sx={{ py: 2 }}
                onClick={() => setOpenUploadModal(true)}
                disabled={isSuspendMutations}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText secondary={"Upload more files"} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>

        <UploaderModal
          open={openUploadModal}
          onRequestClose={() => setOpenUploadModal(false)}
          onUploadSuccess={onUploadSuccess}
          note={"Max 20 files. Each file size should be less than 64 MB"}
        />
      </DialogActions>
    </Dialog>
  );
};

export const attachmentsDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<AttachmentDialogProps, false>) => (
    <Suspense fallback={<Loader />}>
      <AttachmentsDialog {...props} />
    </Suspense>
  ))
);

// src/features/user/user.types.ts
var USER_ROLES = /* @__PURE__ */ (USER_ROLES2 => {
  USER_ROLES2["SUPER_ADMIN"] = "SUPER_ADMIN";
  USER_ROLES2["ADMIN"] = "ADMIN";
  USER_ROLES2["SALES_REP"] = "SALES_REP";
  USER_ROLES2["OFFICE"] = "OFFICE";
  USER_ROLES2["PUBLIC"] = "PUBLIC";
  USER_ROLES2["FITTER"] = "FITTER";
  USER_ROLES2["FACTORY"] = "FACTORY";
  return USER_ROLES2;
})(USER_ROLES || {});

// src/features/order/order.types.ts
var OrderStatusBit = /* @__PURE__ */ (OrderStatusBit2 => {
  OrderStatusBit2[(OrderStatusBit2["EMPTY"] = 0)] = "EMPTY";
  OrderStatusBit2[(OrderStatusBit2["CHECKOUT"] = 1)] = "CHECKOUT";
  OrderStatusBit2[(OrderStatusBit2["OPEN"] = 2)] = "OPEN";
  OrderStatusBit2[(OrderStatusBit2["PROCESSING"] = 4)] = "PROCESSING";
  OrderStatusBit2[(OrderStatusBit2["INVOICED"] = 8)] = "INVOICED";
  OrderStatusBit2[(OrderStatusBit2["COMPLETE"] = 16)] = "COMPLETE";
  return OrderStatusBit2;
})(OrderStatusBit || {});
var OrderStatusV2 = /* @__PURE__ */ (OrderStatusV22 => {
  OrderStatusV22["EMPTY"] = "empty";
  OrderStatusV22["CHECKOUT"] = "checkout";
  OrderStatusV22["OPEN"] = "open";
  OrderStatusV22["PROCESSING"] = "processing";
  OrderStatusV22["INVOICED"] = "invoiced";
  OrderStatusV22["COMPLETE"] = "complete";
  return OrderStatusV22;
})(OrderStatusV2 || {});

// src/features/order_fitting/order_fitting.types.ts
var OrderFittingJobTypes = /* @__PURE__ */ (OrderFittingJobTypes2 => {
  OrderFittingJobTypes2["CHECK_MEASURE"] = "check_measure";
  OrderFittingJobTypes2["DELIVERY"] = "delivery";
  OrderFittingJobTypes2["INSPECTION"] = "inspection";
  OrderFittingJobTypes2["INSTALL"] = "install";
  OrderFittingJobTypes2["EXTRA_INSTALL"] = "extra_install";
  OrderFittingJobTypes2["PICKUP"] = "pickup";
  OrderFittingJobTypes2["PRE_FIT"] = "pre_fit";
  OrderFittingJobTypes2["RECTIFICATION"] = "rectification";
  OrderFittingJobTypes2["SERVICE"] = "service";
  OrderFittingJobTypes2["WARRANTY"] = "warranty";
  OrderFittingJobTypes2["NOTES"] = "notes";
  OrderFittingJobTypes2["FREIGHT"] = "freight";
  OrderFittingJobTypes2["SUPPLY_ONLY"] = "supply_only";
  return OrderFittingJobTypes2;
})(OrderFittingJobTypes || {});
var OrderFittingInstallConfirmationStatus =
  /* @__PURE__ */ (OrderFittingInstallConfirmationStatus2 => {
    OrderFittingInstallConfirmationStatus2["CONFIRMED"] = "confirmed";
    OrderFittingInstallConfirmationStatus2["UNCONFIRMED"] = "unconfirmed";
    OrderFittingInstallConfirmationStatus2["PENDING"] = "pending";
    OrderFittingInstallConfirmationStatus2["LEFT_MESSAGE"] = "left_message";
    return OrderFittingInstallConfirmationStatus2;
  })(OrderFittingInstallConfirmationStatus || {});
var OrderFittingJobStatus = /* @__PURE__ */ (OrderFittingJobStatus2 => {
  OrderFittingJobStatus2["COMPLETE"] = "complete";
  OrderFittingJobStatus2["RECTIFICATION"] = "rectification";
  OrderFittingJobStatus2["TO_BE_REBOOKED"] = "to_be_rebooked";
  OrderFittingJobStatus2["BACK_TO_REP"] = "back_to_rep";
  return OrderFittingJobStatus2;
})(OrderFittingJobStatus || {});
export {
  OrderFittingInstallConfirmationStatus,
  OrderFittingJobStatus,
  OrderFittingJobTypes,
  OrderStatusBit,
  OrderStatusV2,
  USER_ROLES
};

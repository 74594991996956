import OrderFittingList from "./OrderFittingList.tsx";
import asyncComponent from "../../components/Async.tsx";

const BookedList = asyncComponent(() => import("./BookedList.tsx"));
const OrderFittingCalendar = asyncComponent(
  () => import("../order_fitting/calendar/OrderFittingCalendar.tsx")
);

const routes = [
  {
    path: "/order-fitting",
    element: <OrderFittingList />,
    children: [
      {
        path: "",
        element: <BookedList />
      }
    ]
  },
  {
    path: "/order-fitting-calendar",
    element: <OrderFittingCalendar />
  }
];

export default routes;

import React from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../components/ReactConfirmMountPoint";
import { useSnackbar } from "notistack";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";

export const AfterOrderSplitDialog = confirmable(
  ({
    show,
    proceed,
    reference_number,
    split_orders,
    customer_first_name
  }: ConfirmDialogProps<
    {
      order_id: number;
      reference_number: string;
      customer_first_name: string;
      split_orders: { uuid: string; reference_number: string; id: number; sold_price: number }[];
    },
    boolean
  >) => {
    const handleClose = () => proceed(false);
    const { enqueueSnackbar } = useSnackbar();
    const [firstSplit, secondSplit] = split_orders;

    return (
      <Dialog
        open={show}
        disableEscapeKeyDown
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Done</DialogTitle>
        <DialogContent>
          <Typography>Order {reference_number} split successfully</Typography>
          <br />
          <Card variant="outlined">
            <CardContent
              id={"content"}
              contentEditable
              onKeyDown={e => e.preventDefault()}
              onClick={() => {
                if (!window.getSelection) {
                  enqueueSnackbar("window.getSelection not supported", {
                    variant: "error"
                  });

                  return;
                }

                const contentEle = document.getElementById("content");
                if (contentEle) {
                  const range = document.createRange();
                  range.selectNodeContents(contentEle);
                  const selection = window.getSelection();
                  if (selection) {
                    selection.removeAllRanges();
                    selection.addRange(range);

                    document.execCommand("copy");

                    enqueueSnackbar("Copied!", {
                      variant: "success"
                    });
                  }
                }
              }}
            >
              <p>{`Thank you for choosing One Solomons ${customer_first_name}.`}</p>

              <p>
                <b>Important message:</b> Your order has now been split into two different
                installations due to lead times on products.
              </p>
              <p>Your original payment has been split across the two orders as outlined below:</p>
              <p>
                <b>{`Order ${firstSplit.reference_number}`}</b>
              </p>
              <p>{`${toDecimalFromFloat(firstSplit?.sold_price ?? 0)}`}</p>
              <p>
                <b>{`Order ${secondSplit.reference_number}`}</b>
              </p>
              <p>{`${toDecimalFromFloat(secondSplit?.sold_price ?? 0)}`}</p>
              <p> Please refer to the following new links for the progress of your orders.</p>
              <p>Installation 1</p>
              <p>
                <a
                  href={`${window.location.origin}/guest/order/${firstSplit.uuid}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${window.location.origin}/guest/order/${firstSplit.uuid}`}</a>
              </p>
              <p>Installation 2</p>
              <p>
                <a
                  href={`${window.location.origin}/guest/order/${secondSplit.uuid}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${window.location.origin}/guest/order/${secondSplit.uuid}`}</a>
              </p>

              <p>
                <b>
                  <i>
                    We will send you an email and SMS as soon as the first part of your order is
                    ready for installation.
                  </i>
                </b>
              </p>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 4, mx: 4 }}>
          <Stack direction="column" spacing={3} flexGrow={1}>
            <Typography>Do you want to archive this order now?</Typography>
            <Typography variant="caption">
              WARNING: All existing lines on the fitting board will be removed once archived
            </Typography>
          </Stack>

          <Button onClick={handleClose} color="primary">
            I&apos;ll archive later
          </Button>
          <Button onClick={() => proceed(true)} color="primary" autoFocus>
            Archive now
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const afterOrderSplitConfirmationDialog = createConfirmation(AfterOrderSplitDialog);

import { useQueryOrderFormOptions } from "../../api/Order.ts";
import {
  gridFilterModelSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import React, { Suspense, useDeferredValue, useEffect, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { ToolbarStatistics } from "./ToolbarStatistics.tsx";
import Loader from "../../components/Loader.tsx";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { useQueryParamAvailability } from "./OrderList.tsx";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { Link } from "react-router-dom";
import qs from "qs";
import AddIcon from "@mui/icons-material/Add";
import { Can } from "../../casl.ts";
import { endOfDay } from "date-fns";
import { isEmpty } from "lodash";
import useHealthChecker from "../../hooks/useHealthChecker.ts";

export const DataGridToolbar = ({
  additionalQueryParams
}: {
  additionalQueryParams?: Record<string, string | undefined | null>;
}) => {
  const apiRef = useGridApiContext();

  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const filterItemCustomerGroup = filterModel?.items?.find(item => item.id === "customer_group");

  const deferredFilterCustomerGroup = useDeferredValue(filterItemCustomerGroup?.value ?? null);

  const filterItemFilterDateBetween = useMemo(
    () => filterModel?.items?.find(item => item.id === "date_between_by"),
    [filterModel]
  );

  const { data: formOptions } = useQueryOrderFormOptions();

  const [filterDateBy, setFilterDateBy] = useState<string | undefined>(undefined);
  const deferredFilterDateBy = useDeferredValue(filterDateBy);
  const [filterDateBetween, setFilterDateBetween] = useState<DateRange<Date>>();
  const deferredFilterDateBetween = useDeferredValue(filterDateBetween);
  const filterItemOrderType = filterModel?.items?.find(
    item => item.field === "order_type" && item.operator === "isAnyOf"
  );
  const deferredFilterOrderType = useDeferredValue(filterItemOrderType?.value ?? []);
  const [availability, setAvailability] = useQueryParamAvailability();
  const { isSuspendMutations } = useHealthChecker();

  useEffect(() => {
    if (!filterItemFilterDateBetween) {
      setFilterDateBetween([null, null]);
      setFilterDateBy(undefined);
    }
  }, [filterItemFilterDateBetween, setFilterDateBetween]);

  useEffect(() => {
    if (!filterDateBy || filterDateBetween?.every(val => !val)) {
      apiRef.current.deleteFilterItem({
        field: filterDateBy ?? "",
        value: filterDateBetween,
        id: "date_between_by",
        operator: "date_between"
      });
      apiRef.current.setColumnVisibility("is_invoiced_at", false);
      apiRef.current.setColumnVisibility("quote_accepted_at", false);
      apiRef.current.setColumnVisibility("created_at", true);
      return;
    }

    apiRef.current.setColumnVisibility("is_invoiced_at", filterDateBy === "is_invoiced_at");
    apiRef.current.setColumnVisibility("quote_accepted_at", filterDateBy === "quote_accepted_at");
    apiRef.current.setColumnVisibility("created_at", filterDateBy === "created_at");

    apiRef.current.upsertFilterItem({
      field: filterDateBy,
      value: filterDateBetween,
      id: "date_between_by",
      operator: "date_between"
    });
  }, [apiRef, filterDateBetween, filterDateBy, setFilterDateBetween]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={3}
      m={3}
      my={4}
    >
      <Stack gap={3} direction="row" flexWrap="wrap" flexGrow={1}>
        {!!filterItemFilterDateBetween && (
          <Suspense fallback={<Loader />}>
            <ToolbarStatistics additionalQueryParams={additionalQueryParams} />
          </Suspense>
        )}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
      >
        <FormGroup row>
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="date-select-label" margin="dense" shrink>
              Date Filter
            </InputLabel>
            <Select
              labelId="date-select-label"
              id="date-select"
              value={deferredFilterDateBy ?? ""}
              label="Date Filter"
              size="small"
              autoWidth
              onChange={e => setFilterDateBy(e.target.value)}
            >
              <MenuItem>
                <em>None</em>
              </MenuItem>
              <MenuItem value="created_at">Quoted date</MenuItem>
              <MenuItem value="quote_accepted_at">Accepted date</MenuItem>
              <MenuItem value="is_invoiced_at">Invoiced date</MenuItem>
            </Select>
          </FormControl>

          <DateRangePicker
            localeText={{ start: "From", end: "To" }}
            value={deferredFilterDateBetween ?? [null, null]}
            disabled={!deferredFilterDateBy}
            onChange={value => {
              if (value.every(val => val !== null)) {
                const [D1, D2] = value as [Date, Date];
                setFilterDateBetween([D1, endOfDay(D2)]);
              }
            }}
            slotProps={{
              textField: {
                size: "small",
                sx: { width: 100 }
              }
            }}
          />

          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="date-select-label" margin="dense" shrink>
              Order Type
            </InputLabel>
            <Select
              labelId="date-select-label"
              id="date-select"
              value={deferredFilterOrderType}
              label="Date Filter"
              size="small"
              autoWidth
              multiple
              renderValue={selected => {
                if (selected.length === 0) {
                  return <em>Filter</em>;
                }

                return selected.join(", ");
              }}
              onChange={e => {
                const value = e.target.value;
                if (isEmpty(value) && filterItemOrderType) {
                  apiRef.current.deleteFilterItem(filterItemOrderType);
                } else {
                  apiRef.current.upsertFilterItem({
                    field: "order_type",
                    value,
                    id: "order_type",
                    operator: "isAnyOf"
                  });
                }
              }}
            >
              <MenuItem>
                <em>None</em>
              </MenuItem>
              {formOptions?.order_types?.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>

        <FormControl size="small">
          <InputLabel
            id="filter-customer-group-label"
            shrink
            margin="dense"
            sx={{ backgroundColor: "white", padding: 0.5 }}
          >
            Customer Group
          </InputLabel>
          <ToggleButtonGroup
            value={deferredFilterCustomerGroup}
            size="small"
            exclusive
            onChange={(e, value) => {
              if (value === null && filterItemCustomerGroup) {
                apiRef.current.deleteFilterItem(filterItemCustomerGroup);
              } else {
                apiRef.current.upsertFilterItem({
                  field: "customer.customer_group.id",
                  value,
                  id: "customer_group",
                  operator: "is"
                });
              }
            }}
          >
            {formOptions?.customer_groups?.map((customerGroup: any) => {
              return (
                <ToggleButton key={customerGroup.id} value={customerGroup.id}>
                  {customerGroup.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </FormControl>

        <FormControl size="small">
          <ToggleButtonGroup
            value={availability}
            size="small"
            exclusive
            onChange={(e, value) => {
              setAvailability(value);
              apiRef.current.setColumnVisibility("deleted_at", value === "deleted");
            }}
          >
            <Can I="RestoreDeletedOrder" a="order">
              <Tooltip title="Deleted">
                <ToggleButton value="deleted">
                  <DeleteOutlinedIcon />
                </ToggleButton>
              </Tooltip>
            </Can>

            <Tooltip title="Archived">
              <ToggleButton value="archived">
                <ArchiveOutlinedIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </FormControl>

        <GridToolbarContainer>
          <GridToolbarQuickFilter debounceMs={500} sx={{ width: 150 }} />
          <GridToolbarFilterButton />
          <Can I="CreateOrder" a="order">
            <Button
              component={Link}
              to={
                "/order/add/step-1" +
                qs.stringify(
                  {
                    navigate_to: location.pathname + location.search
                  },
                  { addQueryPrefix: true }
                )
              }
              variant="text"
              color="secondary"
              size="small"
              disabled={isSuspendMutations}
            >
              <AddIcon />
              Add
            </Button>
          </Can>
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography as MuiTypography
} from "@mui/material";
import React, { useDeferredValue } from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { format, parseISO } from "date-fns";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { statisticsDateRangeFilter, useQuerySalesRepStatistics } from "../../../api/User.ts";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useAtom } from "jotai";

interface TypographyProps extends SpacingProps {
  component?: string;
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing);
const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
  min-height: 26px;

  & > .MuiAccordionSummary-content {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

function SalesRepStatisticsCard({ sales_rep_user_uuids }: { sales_rep_user_uuids?: string[] }) {
  const [dateRange, setDateRange] = useAtom(statisticsDateRangeFilter);
  const deferredDateRange = useDeferredValue(dateRange);
  const { data: statistics } = useQuerySalesRepStatistics(sales_rep_user_uuids ?? [], dateRange);

  return (
    <CardContent>
      <List sx={{ width: 300 }} dense>
        <ListItem>
          <DateRangePicker
            localeText={{ start: "From", end: "To" }}
            value={deferredDateRange ?? [null, null]}
            onChange={value => {
              if (value.every(val => val !== null)) {
                setDateRange(value);
              }
            }}
            slotProps={{
              textField: {
                size: "small"
              }
            }}
          />
        </ListItem>

        <ListItem secondaryAction={<Typography>{statistics?.quotes_count ?? 0}</Typography>}>
          <ListItemText primary={<Typography>Quote:</Typography>} />
        </ListItem>

        <ListItem secondaryAction={<Typography>{statistics?.orders_count ?? 0}</Typography>}>
          <ListItemText primary={<Typography>Orders:</Typography>} />
        </ListItem>

        <ListItem sx={{ borderRadius: "5px 5px 5px 5px", border: "1px solid" }}>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>Order value:</span>

              <span>{toDecimalFromFloat(statistics?.order_value?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {!statistics?.order_value?.rows && (
                <Typography variant="caption">No orders</Typography>
              )}

              <ListOrders
                orders={statistics?.order_value?.rows ?? []}
                date_attribute={{ label: "Accepted", attribute: "quote_accepted_at" }}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem
          secondaryAction={<Typography>{statistics?.conversions_percentage ?? 0}%</Typography>}
        >
          <ListItemText primary={<Typography>Conversions:</Typography>} />
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>Quoted value:</span>

              <span>{toDecimalFromFloat(statistics?.quoted_value?.sum ?? 0)}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOrders
                orders={statistics?.quoted_value?.rows ?? []}
                date_attribute={{ label: "Quoted", attribute: "created_at" }}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>

        <ListItem>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary>
              <span>Overdue call backs:</span>

              <span>{statistics?.outstanding_callbacks?.count ?? 0}</span>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListOrders
                orders={statistics?.outstanding_callbacks?.rows ?? []}
                date_attribute={{ label: "Overdue", attribute: "created_at" }}
              />
            </AccordionDetails>
          </Accordion>
        </ListItem>
      </List>
    </CardContent>
  );
}

export default SalesRepStatisticsCard;

export const ListOrders = ({
  orders,
  date_attribute
}: {
  orders: any[];
  date_attribute: { label: string; attribute: string };
}) => (
  <List dense disablePadding>
    {orders?.map(row => {
      return (
        <ListItem
          key={row.uuid}
          disablePadding
          secondaryAction={
            <Stack direction="column">
              <span>{toDecimalFromFloat(row.sold_price ?? 0)}</span>
            </Stack>
          }
        >
          <ListItemText
            primary={
              <Stack direction="column">
                <Link
                  component={RouterLink}
                  to={generatePath("/order/:uuid/finalized-quote", {
                    uuid: row.uuid
                  })}
                  target="_blank"
                >
                  {row.reference_number}
                </Link>
                {row[date_attribute.attribute] && (
                  <span>
                    {date_attribute.label}:{" "}
                    {format(parseISO(row[date_attribute.attribute]), "dd/MM/yyyy")}
                  </span>
                )}
              </Stack>
            }
          />
        </ListItem>
      );
    })}
  </List>
);

export const ListOrdersDiscount = ({ orders }: { orders: any[] }) => (
  <List dense disablePadding>
    {orders?.map(row => {
      return (
        <ListItem
          key={row.uuid}
          disablePadding
          secondaryAction={
            <Stack direction="column">
              <span>{row.discount ?? 0}%</span>
            </Stack>
          }
        >
          <ListItemText
            primary={
              <Stack direction="column">
                <Link
                  component={RouterLink}
                  to={generatePath("/order/:uuid/finalized-quote", {
                    uuid: row.uuid
                  })}
                  target="_blank"
                >
                  {row.reference_number}
                </Link>
                {/*<span>Invoiced: {format(parseISO(row.is_invoiced_at), "dd/MM/yyyy")}</span>*/}
              </Stack>
            }
          />
        </ListItem>
      );
    })}
  </List>
);

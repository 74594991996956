import React from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Grid,
  Link as MuiLink,
  List,
  Stack,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import { useQueryOrderLineItemsByOrderId } from "../../../api/OrderLineItem";
import { Link, useParams } from "react-router-dom";
import { useMutationCompleteOrder, useQueryOneOrderPublic } from "../../../api/Order";
import CartSummaryTable from "../form/CartSummaryTable";
import { Helmet } from "react-helmet-async";
import useIsLoading from "../../../hooks/useIsLoading";
import { compareAsc, format, parseISO } from "date-fns";
import { AccordionOrderLineItem } from "../components/AccordionOrderLineItem";
import PaymentTermsTable from "../form/PaymentTermsTable";
import CustomerDetailsCardV2 from "../../customer/CustomerDetailsCardV2";
import { useQueryOneCompanyByCustomerGroupId } from "../../../api/Company";
import TransactionsTable from "../form/TransactionsTable";
import { OptionsButton } from "./OptionsButton";
import { OrderProgress } from "../components/OrderProgress.tsx";
import { orderCompleteConfirmationDialog } from "./OrderCompleteDialog.tsx";
import { usePaymentUrlForTransaction } from "../../../hooks/usePaymentUrlForTransaction.ts";
import useLoadingBackdrop from "../../../hooks/useLoadingBackdrop.ts";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Image = styled.img(spacing);
const Alert = styled(MuiAlert)(spacing);

const OrderQuoteFinalized = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrderPublic(uuid);
  const { customer_group_id } = order?.customer ?? {};
  const { data: company } = useQueryOneCompanyByCustomerGroupId(customer_group_id);

  const paymentUrlDeposit = usePaymentUrlForTransaction("deposit", order, company);
  const paymentUrlPreDelivery = usePaymentUrlForTransaction("pre-delivery", order, company);
  const paymentUrlFinal = usePaymentUrlForTransaction("final", order, company);

  const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order?.id);
  const isLoading = useIsLoading();

  if (!order?.is_quote_finalized) {
    return (
      <>
        <Helmet title={`Order ${order?.reference_number}`} />

        <Card mb={3}>
          <CardContent>Not yet finalized</CardContent>
        </Card>
      </>
    );
  }

  const depositPendingEft =
    !!order?.quote_deposit_payment_initiated_at &&
    order?.quote_deposit_payment_initiated_using === "eft" &&
    !order?.deposit_amount_paid_at;

  const paymentButtons = [
    paymentUrlDeposit && (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={paymentUrlDeposit}
        target="_blank"
      >
        {order?.quote_accepted_at ? "Pay Deposit" : "Accept Order"}
      </Button>
    ),
    // From Andrew: if I invoice a job today with an invoice date of next Tuesday, the mark complete button doesn't appear until 8:00am local time in next Tuesdays date. Prior to that, nothing changes.
    !order?.mark_completed_at &&
      !!order?.is_invoiced_at &&
      compareAsc(new Date(), parseISO(order?.is_invoiced_at)) > 0 && (
        <OrderCompleteButton
          payment_url={[paymentUrlDeposit, paymentUrlPreDelivery, paymentUrlFinal].find(Boolean)}
        />
      ),
    paymentUrlPreDelivery && (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={paymentUrlPreDelivery}
        target="_blank"
      >
        Pay Pre-Delivery
      </Button>
    ),
    paymentUrlFinal && (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={paymentUrlFinal}
        target="_blank"
      >
        Pay Final Balance
      </Button>
    )
  ];

  return (
    <Stack direction="column" spacing={3} useFlexGap>
      <Helmet title={`Order ${order?.reference_number}`} />

      {Boolean(order?.is_test_mode) && (
        <Card>
          <Alert severity="warning">
            <AlertTitle>TEST ORDER</AlertTitle>
            Payments will be marked as TEST mode.
          </Alert>
        </Card>
      )}

      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}
            >
              {company?.image && (
                <Image
                  style={{
                    maxWidth: 300,
                    height: "auto"
                  }}
                  mr={6}
                  src={company?.image}
                  alt={company?.name}
                />
              )}

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography variant="h3" textTransform="capitalize" gutterBottom>{`${
                    order?.order_type
                  } ${order?.reference_number}  ${
                    order.quote_accepted_at && !order?.is_invoiced
                      ? "Pro Forma Invoice"
                      : order?.quote_accepted_at && order?.is_invoiced
                      ? "Tax Invoice"
                      : ""
                  }`}</Typography>

                  {isLoading && <CircularProgress mx={2} size={25} />}
                </Box>

                {!!order?.notes_reference && (
                  <Typography variant="h6" gutterBottom>
                    {order?.notes_reference}
                  </Typography>
                )}

                {order?.sales_rep && (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<span>|</span>}
                    alignItems="center"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <Typography variant="caption">
                      {`Prepared by ${order?.sales_rep?.full_name}`}
                    </Typography>
                    <Typography variant="caption">
                      {format(new Date(order?.createdAt), "dd/MM/yyyy p")}
                    </Typography>
                    {order?.sales_rep?.email && (
                      <MuiLink href={`mailto:${order?.sales_rep?.email}`} variant="caption">
                        {order?.sales_rep?.email}
                      </MuiLink>
                    )}
                    {order?.sales_rep?.phone_number && (
                      <MuiLink href={`tel:${order?.sales_rep?.phone_number}`} variant="caption">
                        {order?.sales_rep?.phone_number}
                      </MuiLink>
                    )}
                  </Stack>
                )}

                {order?.last_updated_by_user && (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<span>|</span>}
                    alignItems="center"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <Typography variant="caption">
                      {`Last edited by ${order?.last_updated_by_user?.full_name}`}
                    </Typography>

                    <Typography variant="caption">
                      {format(new Date(order?.updatedAt), "dd/MM/yyyy p")}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Box>

            {!!order?.quote_accepted_at && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h4">Order Accepted</Typography>
                <Typography variant="caption">
                  {format(new Date(order?.quote_accepted_at), "dd/MM/yyyy p")}
                </Typography>

                <Typography variant="caption">
                  {[
                    order?.deposit_amount_paid_at
                      ? "Deposit Processed"
                      : order?.quote_deposit_payment_initiated_at
                      ? "Deposit Pending"
                      : "",
                    !!order?.quote_deposit_payment_initiated_using &&
                      ` (${order?.quote_deposit_payment_initiated_using})`
                  ]
                    .filter(v => v)
                    .join(" ")
                    .trim()}
                </Typography>
              </Box>
            )}

            {!depositPendingEft && paymentButtons.find(Boolean)}

            <OptionsButton />
          </Stack>
        </CardContent>
      </Card>

      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <List>
                {orderLineItems?.map((order_line_item: any) => {
                  return (
                    <AccordionOrderLineItem
                      key={order_line_item.uuid}
                      order_line_item={order_line_item}
                      public_view
                    />
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={3} useFlexGap>
            <Card>
              <CustomerDetailsCardV2
                site_address={order?.site_address}
                customer_uuid={order?.customer?.uuid}
                hideDetailsButton
                hideCreateOrderFromButton
                hideMoreOptionsButton
              />
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Summary
                </Typography>

                <CartSummaryTable order={order} />

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Button
                    sx={{ alignSelf: "center" }}
                    href="/pages/terms--conditions"
                    target="_blank"
                  >
                    Terms & Conditions
                  </Button>
                  <Button sx={{ alignSelf: "center" }} href="/pages/warranty" target="_blank">
                    Warranty
                  </Button>
                  {order?.qbcc_contract_pdf_file && (
                    <Button
                      sx={{ alignSelf: "center" }}
                      component={Link}
                      to={order?.qbcc_contract_pdf_file?.location_url}
                      target="_blank"
                    >
                      Download QBCC contract
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Payment Terms
                </Typography>

                <PaymentTermsTable order={order} />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <TransactionsTable disablePaymentButton order={order} />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <Card>
        <CardContent
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography>Order Progress:</Typography>

          <OrderProgress status={order?.status_v2} />
        </CardContent>
      </Card>

      {company && (
        <Card>
          <CardContent>
            <Stack
              direction="row"
              spacing={3}
              divider={<span>|</span>}
              justifyContent="center"
              alignItems="center"
              useFlexGap
              flexWrap="wrap"
            >
              {company?.name && <Typography variant="h5">{company?.name}</Typography>}
              {company?.address && <Typography variant="caption">{company?.address}</Typography>}
              {company?.email && (
                <MuiLink href={`mailto:${company?.email}`} variant="caption">
                  {company?.email}
                </MuiLink>
              )}
              {company?.abn && <Typography variant="caption">ABN: {company?.abn}</Typography>}
              {/*{company?.bsb && <Typography variant="caption">BSB: {company?.bsb}</Typography>}*/}
              {/*{company?.acc && <Typography variant="caption">ACC: {company?.acc}</Typography>}*/}
            </Stack>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export default OrderQuoteFinalized;

const OrderCompleteButton = ({ payment_url }: { payment_url?: string | null }) => {
  const { uuid } = useParams<string>();
  const { mutateAsync: orderComplete } = useMutationCompleteOrder();
  const loadingBackdropState = useLoadingBackdrop();

  const handleOrderComplete = async () => {
    const result = await orderCompleteConfirmationDialog({});

    if (result === false) {
      return;
    }

    loadingBackdropState.increment();

    await orderComplete({
      uuid,
      customer_name: result.customer_name
    }).finally(() => loadingBackdropState.decrement());

    payment_url && window.open(payment_url, "_blank", "noopener,noreferrer");
  };

  return (
    <Button variant="contained" color="primary" onClick={handleOrderComplete}>
      Order Complete
    </Button>
  );
};

import React, { useMemo } from "react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import useHealthChecker from "../../hooks/useHealthChecker";
import { Button, Card, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { exportProductsByValueDialog } from "./ExportProductsByValueDialog.tsx";
import { exportedDialog } from "./ExportedDialog.tsx";
import { exportOrderFittingBoardDialog } from "./ExportOrderFittingBoardDialog.tsx";
import { exportOrdersWithCreditDialog } from "./ExportOrdersWithCreditDialog.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { exportCustomersDialog } from "./ExportCustomersDialog.tsx";

const DataExportList = () => {
  useSetPageTitle("Data Exports");

  const { isSuspendMutations } = useHealthChecker();

  const availableForExports = useMemo(() => {
    return [
      {
        name: "Products by Value",
        onClick: async () => {
          const export_uuid = await exportProductsByValueDialog({});
          if (export_uuid) {
            await exportedDialog({
              export_uuid,
              title: "Exported Products by Value"
            });
          }
        }
      },
      {
        name: "Order Fitting Board",
        onClick: async () => {
          const export_uuid = await exportOrderFittingBoardDialog({});
          if (export_uuid) {
            await exportedDialog({
              export_uuid,
              title: "Exported Order Fitting Board"
            });
          }
        }
      },
      {
        name: "Orders with Credit",
        onClick: async () => {
          const export_uuid = await exportOrdersWithCreditDialog({});
          if (export_uuid) {
            await exportedDialog({
              export_uuid,
              title: "Exported Orders with Credit"
            });
          }
        }
      },
      {
        name: "Customers",
        onClick: async () => {
          const export_uuid = await exportCustomersDialog({});
          if (export_uuid) {
            await exportedDialog({
              export_uuid,
              title: "Exported Customers"
            });
          }
        }
      }
    ];
  }, []);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        const { name, onClick } = row;

        return [
          <Tooltip title={`Export ${name}`} key={`export-${name}`}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={onClick}
              sx={{ m: 1 }}
              startIcon={<FileDownloadIcon />}
              disabled={isSuspendMutations}
            >
              Export
            </Button>
          </Tooltip>
        ];
      },
      flex: 1
    }
  ];

  return (
    <Card sx={{ height: "84vh", maxWidth: { xs: "100%", lg: "81vw" } }}>
      <DataGridPro
        sx={{ border: 0 }}
        disableRowSelectionOnClick
        pagination={false}
        columns={columns}
        rows={availableForExports}
        getRowId={({ name }) => name}
      />
    </Card>
  );
};

export default DataExportList;

import axios from "../utils/axios";
import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useMutationRestoreDeletedModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import sleep from "../utils/sleep";
import { useQuery } from "@tanstack/react-query";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { atom } from "jotai";
import { startOfDay, startOfMonth } from "date-fns";

export const useQueryUsers = () => useQueryModels("users");

export const useQueryOneUser = (uuid?: string) => useQueryOneModel<any>("users", uuid);

export const useQueryUserFormOptions = () =>
  useQueryFormOptionsModel<{ roles: string[]; sales_rep_users: any[] }>("users");

export const useMutationAddUser = (uuid: string) => useMutationAddModel("users", uuid);

export const useMutationEditUser = (uuid: string) => useMutationEditModel("users", uuid);

export const useMutationRestoreDeletedUser = () => useMutationRestoreDeletedModel("users");

export const useMutationDeleteUser = () => useMutationDeleteModel("users");

export const useMutationBulkDeleteUser = () => useMutationBulkDeleteModelV2("users");

export const statisticsDateRangeFilter = atom<DateRange<Date>>([
  startOfMonth(new Date()),
  startOfDay(new Date())
]);

export const useMutationInviteUser = (uuid: string) =>
  useMutationAbstract({
    mutationKey: ["users"],
    mutationFn: data =>
      axios
        .post("/api/users/invite", {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(async () => {
          await sleep(1500);
        })
  });

export const useMutationUserSetPassword = () => {
  const { uuid } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  return useMutationAbstract<{
    password: string;
    confirm_password: string;
  }>({
    mutationFn: data =>
      axios.post(`/api/users/set-password/${uuid}`, data).then(({ data }) => data),
    onSuccess: () => {
      enqueueSnackbar("Success", {
        variant: "success",
        preventDuplicate: true
      });
    }
  });
};

export const useQuerySalesRepStatistics = (uuids: string[], dateRange: DateRange<Date>) =>
  useQuery<{
    quotes_count: number;
    orders_count: number;
    conversions_percentage: number;
    avg_discount: { avg_percentage: number; rows: [] };
    order_value: { sum: number; rows: [] };
    quoted_value: { sum: number; rows: [] };
    invoiced: { sum: number; rows: [] };
    outstanding_callbacks: { count: number; rows: [] };
  }>({
    enabled: !!uuids,
    queryKey: [
      "users/sales-rep/statistics",
      "statistics",
      {
        start_date: dateRange[0],
        end_date: dateRange[1],
        // need to do it like this for express to recognize uuids array
        sales_rep: { uuids }
      }
    ]
  });
